import userInformation from 'app/entities/user-information/user-information.reducer';
import systemBank from 'app/entities/system-bank/system-bank.reducer';
import systemSetting from 'app/entities/system-setting/system-setting.reducer';
import apiKey from 'app/entities/api-key/api-key.reducer';
import bank from 'app/entities/bank/bank.reducer';
import requestWithdraw from 'app/entities/request-withdraw/request-withdraw.reducer';
import token from 'app/entities/token/token.reducer';
import depositTransaction from 'app/entities/deposit-transaction/deposit-transaction.reducer';
import withdrawTransaction from 'app/entities/withdraw-transaction/withdraw-transaction.reducer';
import requestManagementRequest from 'app/entities/request-management/request-management.reducer';
import depositError from 'app/entities/deposit-error/deposit-error.reducer';
import ipWhiteList from 'app/entities/ip-white-list/ip-white-list.reducer';
import activityLog from 'app/entities/activity-log/activity-log.reducer';
import regex from 'app/entities/regex/regex.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  userInformation,
  systemBank,
  systemSetting,
  apiKey,
  bank,
  requestWithdraw,
  requestManagementRequest,
  token,
  depositTransaction,
  withdrawTransaction,
  depositError,
  ipWhiteList,
  activityLog,
  regex,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
