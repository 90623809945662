import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IUserInformation {
  id?: string;
  isDeleted?: boolean | null;
  createdDate?: string | null;
  updatedDate?: string | null;
  name?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IUserInformation> = {
  isDeleted: false,
};
