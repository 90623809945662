
import React from 'react';
import { Grid } from '@mui/material';
import RealTimeData from './report-realtime';
import ChartDepositTransaction from '../transaction-count/deposit-today';
import ChartWithDrawnTransaction from '../transaction-count/withdrawn-today';
import NotificationTracker from './notice';
import './home.scss'
export const Home = () => {
  return (
    <div className='home-page'>
      <div className='box-transaction'>
        {/*<div>*/}
        {/*  <NotificationTracker />*/}
        {/*</div>*/}
        <RealTimeData />
        <Grid container spacing={6} sx={{ px: 8, pt: 4 }}>
          <Grid item xs={12} md={12} lg={6}>
            <ChartDepositTransaction />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <ChartWithDrawnTransaction />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Home;
