import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './withdraw-transaction.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const WithdrawTransactionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const withdrawTransactionEntity = useAppSelector(state => state.withdrawTransaction.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="withdrawTransactionDetailsHeading">
          <Translate contentKey="cesPaymentGatewayApp.withdrawTransaction.detail.title">WithdrawTransaction</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{withdrawTransactionEntity.id}</dd>
          <dt>
            <span id="orderId">
              <Translate contentKey="cesPaymentGatewayApp.withdrawTransaction.orderId">Order Id</Translate>
            </span>
          </dt>
          <dd>{withdrawTransactionEntity.orderId}</dd>
          <dt>
            <span id="recipientBankAccountNumber">
              <Translate contentKey="cesPaymentGatewayApp.withdrawTransaction.recipientBankAccountNumber">
                Recipient Bank Account Number
              </Translate>
            </span>
          </dt>
          <dd>{withdrawTransactionEntity.recipientBankAccountNumber}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="cesPaymentGatewayApp.withdrawTransaction.status">Status</Translate>
            </span>
          </dt>
          <dd>{withdrawTransactionEntity.status}</dd>
          {withdrawTransactionEntity?.transfers?.length > 0 && (
            <>
              <dt>
                <span id="transfers">
                  <Translate contentKey="cesPaymentGatewayApp.withdrawTransaction.transfers">Transfers</Translate>
                </span>
              </dt>
              <dd>
                {/* <strong>⦿</strong> */}
                <table style={{ width: '50%', border: '1px solid black' }}>
                  <tr>
                    <th style={{ paddingLeft: '8px', border: '1px solid black' }}>FromBank</th>
                    <th style={{ paddingLeft: '8px', border: '1px solid black' }}>FromBankAccountName</th>
                    <th style={{ paddingLeft: '8px', border: '1px solid black' }}>FromBankAccount</th>
                  </tr>
                  {withdrawTransactionEntity.transfers.map((transfer, key) => (
                    <tr key={key}>
                      <td style={{ paddingLeft: '8px', border: '1px solid black' }}>
                        {transfer?.fromBank && <span> {transfer?.fromBank}</span>}
                      </td>
                      <td style={{ paddingLeft: '8px', border: '1px solid black' }}>
                        {transfer?.fromBankAccountName && <span> {transfer?.fromBankAccountName}</span>}
                      </td>
                      <td style={{ paddingLeft: '8px', border: '1px solid black' }}>
                        {transfer?.fromBankAccount && <span> {transfer?.fromBankAccount}</span>}
                      </td>
                    </tr>
                  ))}
                </table>
              </dd>
            </>
          )}

          <dt>
            <span id="isDeleted">
              <Translate contentKey="cesPaymentGatewayApp.withdrawTransaction.isDeleted">Is Deleted</Translate>
            </span>
          </dt>
          <dd>{withdrawTransactionEntity.isDeleted ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="cesPaymentGatewayApp.withdrawTransaction.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {withdrawTransactionEntity.createdDate ? (
              <TextFormat value={withdrawTransactionEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="cesPaymentGatewayApp.withdrawTransaction.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>
            {withdrawTransactionEntity.updatedDate ? (
              <TextFormat value={withdrawTransactionEntity.updatedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="cesPaymentGatewayApp.withdrawTransaction.requestWithdraw">Request Withdraw</Translate>
          </dt>
          <dd>{withdrawTransactionEntity.requestWithdraw ? withdrawTransactionEntity.requestWithdraw.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/withdraw-transaction" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {isAdmin && (
          <Button tag={Link} to={`/withdraw-transaction/${withdrawTransactionEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default WithdrawTransactionDetail;
