import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IDepositError, defaultValue } from 'app/shared/model/deposit-error.model';
import { convertLocalDateToInstant } from '../../shared/util/date-utils';
import { toast } from 'react-toastify';
import { translate } from 'react-jhipster';

const initialState: EntityState<IDepositError> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export interface IDepositErrorQuery {
  page: number;
  size: number;
  fromToDate?: Array<any>;
  amount?: string;
  login?: string;
  depositId?: string;
}

const apiUrl = 'api/deposit-errors';
const apiExtendUrl = 'api/get-deposit-errors-by-condition';

// Actions

export const getEntities = createAsyncThunk('depositError/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IDepositError[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'depositError/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IDepositError>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getFilterData = createAsyncThunk(
  'requestWithdraw/fetch_filter_data_list',
  async ({ page, size, fromToDate, amount, login, depositId }: IDepositErrorQuery) => {
    const requestUrl = `${apiExtendUrl}/${`?page=${page}&size=${size}${
      fromToDate?.length === 2 ? `&fromDate=${convertLocalDateToInstant(fromToDate[0])}` : ''
    }${fromToDate?.length === 2 ? `&toDate=${convertLocalDateToInstant(fromToDate[1])}` : ''}${amount ? `&amount=${amount}` : ''}${
      login ? `&login=${login}` : ''
    }${depositId ? `&depositId=${depositId}` : ''}`}`;
    return axios.get<IDepositError[]>(requestUrl);
  }
);

export const createEntity = createAsyncThunk(
  'depositError/create_entity',
  async (entity: IDepositError, thunkAPI) => {
    const result = await axios.post<IDepositError>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'depositError/update_entity',
  async (entity: IDepositError, thunkAPI) => {
    const result = await axios.put<IDepositError>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const refund = createAsyncThunk(
  'depositError/refund',
  async (entity: any, thunkAPI) => {
    const body = {
      depositErrorId: entity?.id,
      message: entity?.message,
      amount: entity?.amount,
      id: entity?.login,
      recipientBankAccountNumber: entity?.recipientBankAccountNumber,
      orderId: entity?.orderId,
    };
    const result = await axios.post<any>(`/api/refund`, body);
    thunkAPI.dispatch(getEntities({}));
    toast.success('Refund successfully');
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'depositError/partial_update_entity',
  async (entity: IDepositError, thunkAPI) => {
    const result = await axios.patch<IDepositError>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'depositError/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IDepositError>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const DepositErrorSlice = createEntitySlice({
  name: 'depositError',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getFilterData), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity, refund), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getFilterData), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity, refund), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = DepositErrorSlice.actions;

// Reducer
export default DepositErrorSlice.reducer;
