import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IRegex } from 'app/shared/model/regex.model';
import { getEntities } from './regex.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const Regex = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const regexList = useAppSelector(state => state.regex.entities);
  const loading = useAppSelector(state => state.regex.loading);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  return (
    <div>
      <h2 id="regex-heading" data-cy="RegexHeading">
        <Translate contentKey="cesPaymentGatewayApp.regex.home.title">Regexes</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="cesPaymentGatewayApp.regex.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/regex/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="cesPaymentGatewayApp.regex.home.createLabel">Create new Regex</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {regexList && regexList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.regex.bank">Bank</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.regex.idRegex">Id Regex</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.regex.moneyRegex">Money Regex</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.regex.bankNumberRegex">Bank Number Regex</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.regex.createdDate">Created Date</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.regex.updatedDate">Updated Date</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {regexList.map((regex, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{regex.bank}</td>
                  <td>{regex.idRegex}</td>
                  <td>{regex.moneyRegex}</td>
                  <td>{regex.bankNumberRegex}</td>
                  <td>{regex.createdDate ? <TextFormat type="date" value={regex.createdDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{regex.updatedDate ? <TextFormat type="date" value={regex.updatedDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/regex/${regex.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      {isAdmin && (
                        <Button tag={Link} to={`/regex/${regex.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                      )}
                      {isAdmin && (
                        <Button tag={Link} to={`/regex/${regex.id}/delete`} color="danger" size="sm" data-cy="entityDeleteButton">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="cesPaymentGatewayApp.regex.home.notFound">No Regexes found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Regex;
