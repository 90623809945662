import dayjs from 'dayjs';

export interface IDepositError {
  id?: string;
  message?: string | null;
  amount?: number | null;
  login?: string | null;
  isDeleted?: boolean | null;
  createdDate?: string | null;
  updatedDate?: string | null;
  reason?: string | null;
  deviceName?: string | null;
  orderId?: string | null;
}

export const defaultValue: Readonly<IDepositError> = {
  isDeleted: false,
};
