import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { getSortState, TextFormat, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, getFilterData } from './api-key.reducer';
import '../../../app/common/style/index.scss';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import FilterOption, { IFormFilterField } from '../../common/FilterOption/FilterOption';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const ApiKey = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isFilterMode, setFilterMode] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', 'desc'), location.search)
  );

  const formField = [{ type: 'text', name: 'deviceName', label: 'Device Name', placeholder: 'Device Name' }];

  const apiKeyList = useAppSelector(state => state.apiKey.entities);
  const loading = useAppSelector(state => state.apiKey.loading);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  return (
    <div className="main-content">
      <h2 id="api-key-heading" data-cy="ApiKeyHeading">
        <Translate contentKey="cesPaymentGatewayApp.apiKey.home.title">Api Keys</Translate>
        <div>
          <FilterOption
            isFilterMode={isFilterMode}
            setFilterMode={setFilterMode}
            setPaginationState={setPaginationState}
            paginationState={paginationState}
            getEntities={getEntities}
            getFilterData={getFilterData}
            labelWidth={130}
            fieldFilters={formField as IFormFilterField[]}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="cesPaymentGatewayApp.apiKey.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/api-key/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="cesPaymentGatewayApp.apiKey.home.createLabel">Create new Api Key</Translate>
          </Link>
        </div>
      </h2>

      <div className="table-responsive">
        {apiKeyList && apiKeyList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.apiKey.key">Key</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.apiKey.uniqueDevice">Unique Device</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.apiKey.deviceName">Device Name</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.apiKey.isActive">Is Active</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.apiKey.createdDate">Created Date</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.apiKey.updatedDate">Updated Date</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {apiKeyList.map((apiKey, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{apiKey.key}</td>
                  <td>{apiKey.uniqueDevice}</td>
                  <td>{apiKey.deviceName}</td>
                  <td>{apiKey.isActive ? 'true' : 'false'}</td>
                  <td>{apiKey.createdDate ? <TextFormat type="date" value={apiKey.createdDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td>{apiKey.updatedDate ? <TextFormat type="date" value={apiKey.updatedDate} format={APP_DATE_FORMAT} /> : null}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/api-key/${apiKey.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      {isAdmin && (
                        <Button tag={Link} to={`/api-key/${apiKey.id}/edit`} color="primary" size="sm" data-cy="entityEditButton">
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                      )}
                      {isAdmin && (
                        <Button tag={Link} to={`/api-key/${apiKey.id}/delete`} color="danger" size="sm" data-cy="entityDeleteButton">
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="cesPaymentGatewayApp.apiKey.home.notFound">No Api Keys found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ApiKey;
