import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RequestWithdraw from './request-withdraw';
import RequestWithdrawDetail from './request-withdraw-detail';
import RequestWithdrawUpdate from './request-withdraw-update';
import RequestWithdrawDeleteDialog from './request-withdraw-delete-dialog';

const RequestWithdrawRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RequestWithdraw />} />
    <Route path="new" element={<RequestWithdrawUpdate />} />
    <Route path=":id">
      <Route index element={<RequestWithdrawDetail />} />
      <Route path="edit" element={<RequestWithdrawUpdate />} />
      <Route path="delete" element={<RequestWithdrawDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RequestWithdrawRoutes;
