import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getSystemBanks } from '../system-bank/system-bank.reducer';
import { IDepositTransaction } from 'app/shared/model/deposit-transaction.model';
import { getEntities, getFilterData } from './deposit-transaction.reducer';
import { resetFilter } from '../../shared/util/filter-utils';
import FilterOption, { IFormFilterField } from '../../common/FilterOption/FilterOption';
import { formatCurrency } from 'app/entities/request-management/request-select/request-tag';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const DepositTransaction = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [isFilterMode, setFilterMode] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', 'desc'), location.search)
  );
  const depositTransactionList = useAppSelector(state => state.depositTransaction.entities);
  const loading = useAppSelector(state => state.depositTransaction.loading);
  const totalItems = useAppSelector(state => state.depositTransaction.totalItems);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  const formField = [
    { type: 'selection', name: 'status', option: 'status', label: 'Status', placeholder: 'Status' },
    { type: 'text', name: 'amount', label: 'Amount', placeholder: 'Amount' },
    {
      type: 'selection&search',
      name: 'recipientBankAccountNumber',
      option: 'searchBankAccount',
      label: 'Recipient',
      placeholder: 'Recipient Bank Account Number',
    },
    { type: 'dateTimeRange', name: 'fromDate', label: 'From To Date', placeholder: ['From Date', 'To Date'] },
  ];

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    if (!isFilterMode) {
      sortEntities();
    }
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    resetFilter(setPaginationState, paginationState, setFilterMode, dispatch, getEntities);
    sortEntities();
  };

  return (
    <div>
      <h2 id="deposit-transaction-heading" data-cy="DepositTransactionHeading">
        <Translate contentKey="cesPaymentGatewayApp.depositTransaction.home.title">Deposit Transactions</Translate>
        <div>
          <FilterOption
            isFilterMode={isFilterMode}
            setFilterMode={setFilterMode}
            setPaginationState={setPaginationState}
            paginationState={paginationState}
            getEntities={getEntities}
            getFilterData={getFilterData}
            labelWidth={100}
            fieldFilters={formField as IFormFilterField[]}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="cesPaymentGatewayApp.depositTransaction.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link
            to="/deposit-transaction/new"
            className="btn btn-primary jh-create-entity"
            id="jh-create-entity"
            data-cy="entityCreateButton"
          >
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="cesPaymentGatewayApp.depositTransaction.home.createLabel">Create new Deposit Transaction</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {depositTransactionList && depositTransactionList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('amount')}>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.amount">Amount</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('fee')}>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.fee">Fee</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('message')}>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.message">Message</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('toBankAccount')}>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.toBankAccount">To Bank Account</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('toBankAccountName')}>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.toBankAccountName">To Bank Account Name</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('toBankBranch')}>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.toBankBranch">To Bank Branch</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('toBank')}>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.toBank">To Bank</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdDate')}>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.createdDate">Created Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedDate')}>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.updatedDate">Updated Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.recipientBankAccount">Recipient Bank Account</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.user">User</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.depositTransaction.updatedUser">Updated User</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {depositTransactionList &&
                depositTransactionList.map((depositTransaction, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>
                      <Button tag={Link} to={`/deposit-transaction/${depositTransaction.id}`} color="link" size="sm">
                        {depositTransaction.id}
                      </Button>
                    </td>
                    <td>{formatCurrency(depositTransaction?.amount)}</td>
                    <td>{depositTransaction.fee}</td>
                    <td>{depositTransaction.message}</td>
                    <td>{depositTransaction.status}</td>
                    <td>{depositTransaction.toBankAccount}</td>
                    <td>{depositTransaction.toBankAccountName}</td>
                    <td>{depositTransaction.toBankBranch}</td>
                    <td>{depositTransaction.toBank}</td>
                    <td>
                      {depositTransaction.createdDate ? (
                        <TextFormat type="date" value={depositTransaction.createdDate} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {depositTransaction.updatedDate ? (
                        <TextFormat type="date" value={depositTransaction.updatedDate} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {depositTransaction.recipientBankAccount ? (
                        <Link to={`/system-bank/${depositTransaction.recipientBankAccount.id}`}>
                          {depositTransaction.recipientBankAccount.accountNumber}
                        </Link>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>{depositTransaction.user ? depositTransaction.user.login : ''}</td>
                    <td>{depositTransaction.updatedUser ? depositTransaction.updatedUser.login : ''}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`/deposit-transaction/${depositTransaction.id}`}
                          color="info"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        {isAdmin && (
                          <Button
                            tag={Link}
                            to={`/deposit-transaction/${depositTransaction.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                            color="primary"
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </Button>
                        )}
                        {isAdmin && (
                          <Button
                            tag={Link}
                            to={`/deposit-transaction/${depositTransaction.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                            color="danger"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <FontAwesomeIcon icon="trash" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.home.notFound">No Deposit Transactions found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={depositTransactionList && depositTransactionList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default DepositTransaction;
