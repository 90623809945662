import dayjs from 'dayjs';

export interface ISystemBank {
  id?: string;
  accountNumber?: string;
  accountName?: string | null;
  bankName?: string | null;
  balance?: number | null;
  phoneNumber?: string;
  isDeleted?: boolean | null;
  amountWithdrawn?: number | null;
  amountDeposit?: number | null;
  userWeb?: string | null;
  pwdWeb?: string | null;
  userApp?: string | null;
  pwdApp?: string | null;
  createdDate?: string | null;
  updatedDate?: string | null;
  bankBranch?: string | null;
  deviceName?: string | null;
}

export const defaultValue: Readonly<ISystemBank> = {
  isDeleted: false,
};
