import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserInformation from './user-information';
import SystemBank from './system-bank';
import SystemSetting from './system-setting';
import ApiKey from './api-key';
import Bank from './bank';
import RequestWithdraw from './request-withdraw';
import Token from './token';
import DepositTransaction from './deposit-transaction';
import WithdrawTransaction from './withdraw-transaction';
import DepositError from './deposit-error';
import IpWhiteList from './ip-white-list';
import ActivityLog from './activity-log';
import Regex from './regex';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="user-information/*" element={<UserInformation />} />
        <Route path="system-bank/*" element={<SystemBank />} />
        <Route path="system-setting/*" element={<SystemSetting />} />
        <Route path="api-key/*" element={<ApiKey />} />
        <Route path="bank/*" element={<Bank />} />
        <Route path="request-withdraw/*" element={<RequestWithdraw />} />
        <Route path="token/*" element={<Token />} />
        <Route path="deposit-transaction/*" element={<DepositTransaction />} />
        <Route path="withdraw-transaction/*" element={<WithdrawTransaction />} />
        <Route path="deposit-error/*" element={<DepositError />} />
        <Route path="ip-white-list/*" element={<IpWhiteList />} />
        <Route path="activity-log/*" element={<ActivityLog />} />
        <Route path="regex/*" element={<Regex />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
