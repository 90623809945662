import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IActivityLog {
  id?: string;
  method?: string | null;
  requestURI?: string | null;
  payload?: string | null;
  createdDate?: string | null;
  updatedDate?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IActivityLog> = {};
