import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './ip-white-list.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const IpWhiteListDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const ipWhiteListEntity = useAppSelector(state => state.ipWhiteList.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="ipWhiteListDetailsHeading">
          <Translate contentKey="cesPaymentGatewayApp.ipWhiteList.detail.title">IpWhiteList</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{ipWhiteListEntity.id}</dd>
          <dt>
            <span id="ip">
              <Translate contentKey="cesPaymentGatewayApp.ipWhiteList.ip">Ip</Translate>
            </span>
          </dt>
          <dd>{ipWhiteListEntity.ip}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="cesPaymentGatewayApp.ipWhiteList.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{ipWhiteListEntity.isActive ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="cesPaymentGatewayApp.ipWhiteList.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {ipWhiteListEntity.createdDate ? (
              <TextFormat value={ipWhiteListEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="cesPaymentGatewayApp.ipWhiteList.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>
            {ipWhiteListEntity.updatedDate ? (
              <TextFormat value={ipWhiteListEntity.updatedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/ip-white-list" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {isAdmin && (
          <Button tag={Link} to={`/ip-white-list/${ipWhiteListEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default IpWhiteListDetail;
