import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IRequestWithdraw, defaultValue } from 'app/shared/model/request-withdraw.model';
import { convertLocalDateToInstant } from 'app/shared/util/date-utils';

const initialState: EntityState<IRequestWithdraw> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export interface IRequestWithdrawQuery {
  page: number;
  size: number;
  status?: string;
  fromToDate?: Array<any>;
  amount?: string;
  bankAccountNumber?: string;
  personInCharge?: string;
}

const apiUrl = 'api/request-withdraws';
const apiExtendUrl = 'api/get-withdraw-requests-by-condition';

// Actions

export const getEntities = createAsyncThunk('requestWithdraw/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<IRequestWithdraw[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'requestWithdraw/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IRequestWithdraw>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getFilterData = createAsyncThunk(
  'requestWithdraw/fetch_filter_data_list',
  async ({ page, size, status, fromToDate, amount, bankAccountNumber, personInCharge }: IRequestWithdrawQuery) => {
    const requestUrl = `${apiExtendUrl}/${`?page=${page}&size=${size}${status ? `&status=${status}` : ''}${
      fromToDate?.length === 2 ? `&fromDate=${convertLocalDateToInstant(fromToDate[0])}` : ''
    }${fromToDate?.length === 2 ? `&toDate=${convertLocalDateToInstant(fromToDate[1])}` : ''}${amount ? `&amount=${amount}` : ''}${
      bankAccountNumber ? `&bankAccountNumber=${bankAccountNumber}` : ''
    }${personInCharge ? `&personInCharge=${personInCharge}` : ''}`}`;
    return axios.get<IRequestWithdraw[]>(requestUrl);
  }
);

export const createEntity = createAsyncThunk(
  'requestWithdraw/create_entity',
  async (entity: IRequestWithdraw, thunkAPI) => {
    const result = await axios.post<IRequestWithdraw>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'requestWithdraw/update_entity',
  async (entity: IRequestWithdraw, thunkAPI) => {
    const result = await axios.put<IRequestWithdraw>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'requestWithdraw/partial_update_entity',
  async (entity: IRequestWithdraw, thunkAPI) => {
    const result = await axios.patch<IRequestWithdraw>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'requestWithdraw/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IRequestWithdraw>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const RequestWithdrawSlice = createEntitySlice({
  name: 'requestWithdraw',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getFilterData), (state, action) => {
        const { data, headers } = action.payload;
        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getFilterData), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = RequestWithdrawSlice.actions;

// Reducer
export default RequestWithdrawSlice.reducer;
