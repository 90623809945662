import dayjs from 'dayjs';

export interface IRegex {
  id?: string;
  bank?: string | null;
  idRegex?: string | null;
  moneyRegex?: string | null;
  bankNumberRegex?: string | null;
  createdDate?: string | null;
  updatedDate?: string | null;
}

export const defaultValue: Readonly<IRegex> = {};
