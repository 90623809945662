import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './system-bank.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const SystemBankDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const systemBankEntity = useAppSelector(state => state.systemBank.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="systemBankDetailsHeading">
          <Translate contentKey="cesPaymentGatewayApp.systemBank.detail.title">SystemBank</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.id}</dd>
          <dt>
            <span id="accountNumber">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.accountNumber">Account Number</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.accountNumber}</dd>
          <dt>
            <span id="accountName">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.accountName">Account Name</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.accountName}</dd>
          <dt>
            <span id="bankName">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.bankName">Bank Name</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.bankName}</dd>
          <dt>
            <span id="balance">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.balance">Balance</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.balance}</dd>
          <dt>
            <span id="phoneNumber">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.phoneNumber">Phone Number</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.phoneNumber}</dd>
          <dt>
            <span id="isDeleted">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.isDeleted">Is Deleted</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.isDeleted ? 'true' : 'false'}</dd>
          <dt>
            <span id="amountWithdrawn">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.amountWithdrawn">Amount Withdrawn</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.amountWithdrawn}</dd>
          <dt>
            <span id="amountDeposit">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.amountDeposit">Amount Deposit</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.amountDeposit}</dd>
          <dt>
            <span id="userWeb">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.userWeb">User Web</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.userWeb}</dd>
          <dt>
            <span id="pwdWeb">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.pwdWeb">Pwd Web</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.pwdWeb}</dd>
          <dt>
            <span id="userApp">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.userApp">User App</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.userApp}</dd>
          <dt>
            <span id="pwdApp">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.pwdApp">Pwd App</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.pwdApp}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {systemBankEntity.createdDate ? <TextFormat value={systemBankEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>
            {systemBankEntity.updatedDate ? <TextFormat value={systemBankEntity.updatedDate} type="date" format={APP_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="bankBranch">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.bankBranch">Bank Branch</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.bankBranch}</dd>
          <dt>
            <span id="deviceName">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.deviceName">Device Name</Translate>
            </span>
          </dt>
          <dd>{systemBankEntity.deviceName}</dd>
        </dl>
        <Button tag={Link} to="/system-bank" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {isAdmin && (
          <Button tag={Link} to={`/system-bank/${systemBankEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default SystemBankDetail;
