import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IRequestWithdraw } from 'app/shared/model/request-withdraw.model';
import { getEntities, getFilterData } from './request-withdraw.reducer';
import { formatCurrency } from 'app/entities/request-management/request-select/request-tag';
import FilterOption, { IFormFilterField } from 'app/common/FilterOption/FilterOption';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export interface IRequestWithdrawQuery {
  page: number;
  size: number;
  status?: string;
  fromToDate?: Array<any>;
  amount?: string;
  bankAccountName?: string;
  personInCharge?: string;
}
export const RequestWithdraw = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [isFilterMode, setFilterMode] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', 'desc'), location.search)
  );
  //(filter by date, status, Amount, Bank Account Number, Person In Charge)
  const formField = [
    { type: 'selection', name: 'status', option: 'status', label: 'Status', placeholder: 'Status' },
    { type: 'text', name: 'amount', label: 'Amount', placeholder: 'Amount' },
    { type: 'text', name: 'bankAccountNumber', label: 'Account Number', placeholder: 'Bank Account Number' },
    {
      type: 'selection&search',
      name: 'personInCharge',
      option: 'searchPerson',
      label: 'Person In Charge',
      placeholder: 'Person In Charge',
    },
    { type: 'dateTimeRange', name: 'fromDate', label: 'From To Date', placeholder: ['From Date', 'To Date'] },
  ];

  const requestWithdrawList = useAppSelector(state => state.requestWithdraw.entities);
  const loading = useAppSelector(state => state.requestWithdraw.loading);
  const totalItems = useAppSelector(state => state.requestWithdraw.totalItems);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="request-withdraw-heading" data-cy="RequestWithdrawHeading">
        <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.home.title">Request Withdraws</Translate>
        <div>
          <FilterOption
            isFilterMode={isFilterMode}
            setFilterMode={setFilterMode}
            setPaginationState={setPaginationState}
            paginationState={paginationState}
            getEntities={getEntities}
            getFilterData={getFilterData}
            labelWidth={130}
            fieldFilters={formField as IFormFilterField[]}
          />
        </div>

        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/request-withdraw/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.home.createLabel">Create new Request Withdraw</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {requestWithdrawList && requestWithdrawList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('amount')}>
                  <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.amount">Amount</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('status')}>
                  <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.status">Status</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('confirmedId')}>
                  <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.confirmedId">Confirmed Id</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('bankName')}>
                  <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.bankName">Bank Name</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('branch')}>
                  <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.branch">Branch</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('swiftCode')}>
                  <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.swiftCode">Swift Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('bankAccountName')}>
                  <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.bankAccountName">Bank Account Name</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('bankAccountNumber')}>
                  <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.bankAccountNumber">Bank Account Number</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('createdDate')}>
                  <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.createdDate">Created Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('updatedDate')}>
                  <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.updatedDate">Updated Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.personInCharge">Person In Charge</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {requestWithdrawList &&
                requestWithdrawList.map((requestWithdraw, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>{formatCurrency(requestWithdraw?.amount)}</td>
                    <td>
                      <Translate contentKey={`cesPaymentGatewayApp.BankStatus.${requestWithdraw.status}`} />
                    </td>
                    <td>{requestWithdraw.confirmedId}</td>
                    <td>{requestWithdraw.bankName}</td>
                    <td>{requestWithdraw.branch}</td>
                    <td>{requestWithdraw.swiftCode}</td>
                    <td>{requestWithdraw.bankAccountName}</td>
                    <td>{requestWithdraw.bankAccountNumber}</td>
                    <td>
                      {requestWithdraw.createdDate ? (
                        <TextFormat type="date" value={requestWithdraw.createdDate} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>
                      {requestWithdraw.updatedDate ? (
                        <TextFormat type="date" value={requestWithdraw.updatedDate} format={APP_DATE_FORMAT} />
                      ) : null}
                    </td>
                    <td>{requestWithdraw.personInCharge ? requestWithdraw.personInCharge.id : ''}</td>
                    <td className="text-end">
                      <div className="btn-group flex-btn-group-container">
                        <Button
                          tag={Link}
                          to={`/request-withdraw/${requestWithdraw.id}`}
                          color="info"
                          size="sm"
                          data-cy="entityDetailsButton"
                        >
                          <FontAwesomeIcon icon="eye" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>
                        </Button>
                        {isAdmin && (
                          <Button
                            tag={Link}
                            to={`/request-withdraw/${requestWithdraw.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                            color="primary"
                            size="sm"
                            data-cy="entityEditButton"
                          >
                            <FontAwesomeIcon icon="pencil-alt" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.edit">Edit</Translate>
                            </span>
                          </Button>
                        )}
                        {isAdmin && (
                          <Button
                            tag={Link}
                            to={`/request-withdraw/${requestWithdraw.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                            color="danger"
                            size="sm"
                            data-cy="entityDeleteButton"
                          >
                            <FontAwesomeIcon icon="trash" />{' '}
                            <span className="d-none d-md-inline">
                              <Translate contentKey="entity.action.delete">Delete</Translate>
                            </span>
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.home.notFound">No Request Withdraws found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={requestWithdrawList && requestWithdrawList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default RequestWithdraw;
