import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './activity-log.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const ActivityLogDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const activityLogEntity = useAppSelector(state => state.activityLog.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="activityLogDetailsHeading">
          <Translate contentKey="cesPaymentGatewayApp.activityLog.detail.title">ActivityLog</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{activityLogEntity.id}</dd>
          <dt>
            <span id="method">
              <Translate contentKey="cesPaymentGatewayApp.activityLog.method">Method</Translate>
            </span>
          </dt>
          <dd>{activityLogEntity.method}</dd>
          <dt>
            <span id="requestURI">
              <Translate contentKey="cesPaymentGatewayApp.activityLog.requestURI">Request URI</Translate>
            </span>
          </dt>
          <dd>{activityLogEntity.requestURI}</dd>
          <dt>
            <span id="payload">
              <Translate contentKey="cesPaymentGatewayApp.activityLog.payload">Payload</Translate>
            </span>
          </dt>
          <dd>{activityLogEntity.payload}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="cesPaymentGatewayApp.activityLog.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {activityLogEntity.createdDate ? (
              <TextFormat value={activityLogEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="cesPaymentGatewayApp.activityLog.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>
            {activityLogEntity.updatedDate ? (
              <TextFormat value={activityLogEntity.updatedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="cesPaymentGatewayApp.activityLog.user">User</Translate>
          </dt>
          <dd>{activityLogEntity.user ? activityLogEntity.user.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/activity-log" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {isAdmin && (
          <Button tag={Link} to={`/activity-log/${activityLogEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default ActivityLogDetail;
