import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IToken } from 'app/shared/model/token.model';
import { TokenType } from 'app/shared/model/enumerations/token-type.model';
import { getEntity, updateEntity, createEntity, reset } from './token.reducer';

export const TokenUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const tokenEntity = useAppSelector(state => state.token.entity);
  const loading = useAppSelector(state => state.token.loading);
  const updating = useAppSelector(state => state.token.updating);
  const updateSuccess = useAppSelector(state => state.token.updateSuccess);
  const tokenTypeValues = Object.keys(TokenType);

  const handleClose = () => {
    navigate('/token');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.expiryDate = convertDateTimeToServer(values.expiryDate);
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.updatedDate = convertDateTimeToServer(values.updatedDate);

    const entity = {
      ...tokenEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          expiryDate: displayDefaultDateTime(),
          createdDate: displayDefaultDateTime(),
          updatedDate: displayDefaultDateTime(),
        }
      : {
          type: 'ACCESS',
          ...tokenEntity,
          expiryDate: convertDateTimeFromServer(tokenEntity.expiryDate),
          createdDate: convertDateTimeFromServer(tokenEntity.createdDate),
          updatedDate: convertDateTimeFromServer(tokenEntity.updatedDate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="cesPaymentGatewayApp.token.home.createOrEditLabel" data-cy="TokenCreateUpdateHeading">
            <Translate contentKey="cesPaymentGatewayApp.token.home.createOrEditLabel">Create or edit a Token</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="token-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('cesPaymentGatewayApp.token.token')}
                id="token-token"
                name="token"
                data-cy="token"
                type="text"
                validate={{}}
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.token.expiryDate')}
                id="token-expiryDate"
                name="expiryDate"
                data-cy="expiryDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField label={translate('cesPaymentGatewayApp.token.type')} id="token-type" name="type" data-cy="type" type="select">
                {tokenTypeValues.map(tokenType => (
                  <option value={tokenType} key={tokenType}>
                    {translate('cesPaymentGatewayApp.TokenType.' + tokenType)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('cesPaymentGatewayApp.token.createdDate')}
                id="token-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.token.updatedDate')}
                id="token-updatedDate"
                name="updatedDate"
                data-cy="updatedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/token" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TokenUpdate;
