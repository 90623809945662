import dayjs from 'dayjs';
import { ISystemBank } from 'app/shared/model/system-bank.model';
import { IUser } from 'app/shared/model/user.model';
import { BankStatus } from 'app/shared/model/enumerations/bank-status.model';

export interface IDepositTransaction {
  id?: string;
  amount?: number | null;
  fee?: number | null;
  message?: string | null;
  status?: BankStatus | null;
  fromBankAccount?: string | null;
  fromBankAccountName?: string | null;
  fromBankBranch?: string | null;
  fromBank?: string | null;
  toBankAccount?: string | null;
  toBankAccountName?: string | null;
  toBankBranch?: string | null;
  toBank?: string | null;
  isDeleted?: boolean | null;
  createdDate?: string | null;
  updatedDate?: string | null;
  recipientBankAccount?: ISystemBank | null;
  user?: IUser | null;
  updatedUser?: IUser | null;
}

export const defaultValue: Readonly<IDepositTransaction> = {
  isDeleted: false,
};

export enum EDepositTransactionStatus {
  completeStatus = 'COMPLETED',
  canceledStatus = 'CANCELED',
  openStatus = 'OPEN',
  pendingStatus = 'PENDING',
  refundStatus = 'REFUND',
}
