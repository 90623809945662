import React, { useState } from 'react';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import './index.scss';
import { decryptAES } from '../../entities/system-bank/system-bank.reducer';
interface IShowHidePwd {
  pwd: string;
}

const ShowHidePwd = ({ pwd }: IShowHidePwd) => {
  const [isShowPwd, setShowPwd] = useState(false);

  return (
    <>
      {decryptAES(pwd) && (
        <div className="container-pwd">
          {!isShowPwd ? (
            <EyeOutlined rev={undefined} onClick={() => setShowPwd(true)} />
          ) : (
            <EyeInvisibleOutlined rev={undefined} onClick={() => setShowPwd(false)} />
          )}
          {isShowPwd ? (
            <span className="content">{decryptAES(pwd)}</span>
          ) : (
            <span className="content">{'✱'.repeat(decryptAES(pwd)?.length)}</span>
          )}
        </div>
      )}
    </>
  );
};

export default ShowHidePwd;
