import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { WithDrawnToday } from '../model';
import LoadingIndicator from 'app/common/loadingIndicator';
import ChartTransaction from '../configChart/configchart';
const ChartWithDrawnTransaction: React.FC = () => {
  const [transactionDataToday, setTransactionDataToday] = useState<WithDrawnToday>([] as WithDrawnToday);
  const [loading, setLoading] = useState(true);
  const isWithDrawn = true;
  useEffect(() => {
    async function fetchDepositDataToday() {
      try {
        const response = await axios.get('api/get-report-withdrawn-today');
        setTransactionDataToday(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
    fetchDepositDataToday();
  }, []);
  return (
    <div>
      <div style={{ marginTop: '30px' }}>
        <h5 style={{ textAlign: 'center' }}>Withdrawn Transaction</h5>
        <ChartTransaction transactionDataToday={transactionDataToday as []} isWithDrawn={isWithDrawn} />
      </div>
    </div>
  );
};
export default ChartWithDrawnTransaction;
