import { Box, CircularProgress, Grid } from "@mui/material"
import React from "react"
const LoadingIndicator:React.FC=()=>{
    return(
        <Grid container spacing={3} padding={3} direction='row' margin={"0 auto"} justifyContent='center' >
            <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress/>
            </Box>
        </Grid>
    )
}
export default LoadingIndicator