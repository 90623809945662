import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserInformation from './user-information';
import UserInformationDetail from './user-information-detail';
import UserInformationUpdate from './user-information-update';
import UserInformationDeleteDialog from './user-information-delete-dialog';

const UserInformationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserInformation />} />
    <Route path="new" element={<UserInformationUpdate />} />
    <Route path=":id">
      <Route index element={<UserInformationDetail />} />
      <Route path="edit" element={<UserInformationUpdate />} />
      <Route path="delete" element={<UserInformationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserInformationRoutes;
