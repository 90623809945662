import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Token from './token';
import TokenDetail from './token-detail';
import TokenUpdate from './token-update';
import TokenDeleteDialog from './token-delete-dialog';

const TokenRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Token />} />
    <Route path="new" element={<TokenUpdate />} />
    <Route path=":id">
      <Route index element={<TokenDetail />} />
      <Route path="edit" element={<TokenUpdate />} />
      <Route path="delete" element={<TokenDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TokenRoutes;
