import { FormProps, ProFormDateTimeRangePicker, ProFormSelect, ProFormText, QueryFilter } from '@ant-design/pro-components';
import React, { useEffect, useState } from 'react';
import { resetFilter } from '../../shared/util/filter-utils';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { ISystemBank } from '../../shared/model/system-bank.model';
import { ISystemBankQuery, getEntities as getSystemBanks } from '../../entities/system-bank/system-bank.reducer';
import { IApiKey } from '../../shared/model/api-key.model';
import { AxiosResponse } from 'axios';
import { AsyncThunk } from '@reduxjs/toolkit';
import { IDepositTransactionQuery } from '../../entities/deposit-transaction/deposit-transaction.reducer';
import { EDepositTransactionStatus, IDepositTransaction } from '../../shared/model/deposit-transaction.model';
import { IApiKeyQuery } from '../../entities/api-key/api-key.reducer';
import { IRequestWithdrawQuery } from '../../entities/request-withdraw/request-withdraw.reducer';
import { IRequestWithdraw } from '../../shared/model/request-withdraw.model';
import { IUser } from '../../shared/model/user.model';
import { IWithdrawTransactionQuery } from '../../entities/withdraw-transaction/withdraw-transaction.reducer';
import { IDepositErrorQuery } from '../../entities/deposit-error/deposit-error.reducer';
import { getUsers } from '../../modules/administration/user-management/user-management.reducer';

interface IFilterOption {
  setPaginationState: React.Dispatch<any>;
  paginationState: any;
  isFilterMode: boolean;
  setFilterMode: React.Dispatch<React.SetStateAction<boolean>>;
  getEntities: (arg) => void;
  getFilterData:
    | AsyncThunk<AxiosResponse<ISystemBank[], any>, ISystemBankQuery, {}>
    | AsyncThunk<AxiosResponse<IDepositTransaction[], any>, IDepositTransactionQuery, {}>
    | AsyncThunk<AxiosResponse<IApiKey[], any>, IApiKeyQuery, {}>;
  labelWidth?: number | 'auto' | undefined;
  fieldFilters: IFormFilterField[];
}

export interface IFormFilterField {
  type: 'text' | 'selection' | 'dateTime' | 'dateTimeRange' | 'selection&search';
  name: string;
  label: string;
  placeholder: string | string[] | undefined;
  option?: string;
}

export interface IOption {
  label: string;
  value: string;
}

const FilterOption = ({
  setPaginationState,
  paginationState,
  isFilterMode,
  setFilterMode,
  getEntities,
  getFilterData,
  labelWidth,
  fieldFilters,
}: IFilterOption) => {
  const dispatch = useAppDispatch();
  const [filterValues, setFilterValue] = useState();
  const systemBanks = useAppSelector(state => state.systemBank.entities);
  const users = useAppSelector(state => state.userManagement.users);
  const sortFilterEntities = values => {
    if (!isFilterMode) {
      setFilterMode(true);
      setFilterValue(values);
    }
    dispatch(
      getFilterData({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        ...values,
      } as ISystemBankQuery | IDepositTransactionQuery | IApiKeyQuery | IRequestWithdrawQuery | IWithdrawTransactionQuery | IDepositErrorQuery)
    );
  };

  const checkFetchingData = fieldFilters => {
    for (let index = 0; index < fieldFilters.length; index++) {
      const element = fieldFilters[index];
      if (element?.option === 'searchBankAccount') {
        dispatch(getSystemBanks({}));
      }
      if (element?.option === 'searchPerson') {
        dispatch(getUsers({}));
      }
    }
  };

  useEffect(() => {
    checkFetchingData(fieldFilters);
  }, []);
  const getSelection = (option: string) => {
    const arr = [] as IOption[];
    switch (option) {
      case 'status':
        return [
          {
            value: EDepositTransactionStatus.canceledStatus,
            label: EDepositTransactionStatus.canceledStatus,
          },
          {
            value: EDepositTransactionStatus.completeStatus,
            label: EDepositTransactionStatus.completeStatus,
          },
          {
            value: EDepositTransactionStatus.openStatus,
            label: EDepositTransactionStatus.openStatus,
          },
          {
            value: EDepositTransactionStatus.pendingStatus,
            label: EDepositTransactionStatus.pendingStatus,
          },
          {
            value: EDepositTransactionStatus.refundStatus,
            label: EDepositTransactionStatus.refundStatus,
          },
        ];
      case 'searchBankAccount':
        systemBanks?.forEach((element: ISystemBank) => {
          systemBanks.length > 0 && arr.push({ value: element?.id as string, label: element?.accountNumber as string });
        });
        return arr;
      case 'searchPerson':
        users?.forEach((element: IUser) => {
          users.length > 0 && arr.push({ value: element?.login as string, label: element?.login as string });
        });
        return arr;
      default:
        return [];
    }
  };
  useEffect(() => {
    if (isFilterMode) {
      sortFilterEntities(filterValues);
    }
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);
  return (
    <QueryFilter
      defaultCollapsed={false}
      collapseRender={false}
      labelWidth={labelWidth}
      layout={{ labelAlign: 'left' } as unknown as FormProps['layout']}
      resetText="Reset value"
      searchText="Filter"
      split
      onFinish={async values => {
        setPaginationState({
          ...paginationState,
          activePage: 1,
        });
        sortFilterEntities(values);
      }}
      onReset={() => {
        resetFilter(setPaginationState, paginationState, setFilterMode, dispatch, getEntities);
      }}
    >
      {fieldFilters.map((field: IFormFilterField) => (
        <>
          {field?.type === 'text' && (
            <ProFormText key={field?.name} name={field?.name} label={field?.label} placeholder={field?.placeholder} />
          )}
          {field?.type === 'selection' && field?.option && (
            <ProFormSelect
              key={field?.name}
              options={getSelection(field?.option)}
              name={field?.name}
              label={field?.label}
              placeholder={field?.placeholder}
            />
          )}
          {field?.type === 'selection&search' && field?.option && (
            <ProFormSelect
              key={field?.name}
              showSearch={true}
              allowClear={false}
              mode="single"
              fieldProps={{
                optionFilterProp: 'value',
                autoClearSearchValue: true,
              }}
              options={getSelection(field?.option)?.map(item => {
                return { label: item.label, value: item.value };
              })}
              name={field?.name}
              label={field?.label}
              placeholder={field?.placeholder}
            />
          )}
          {field?.type === 'dateTimeRange' && (
            <ProFormDateTimeRangePicker name="fromToDate" dataFormat="" placeholder={field?.placeholder} label={field?.label} />
          )}
        </>
      ))}
    </QueryFilter>
  );
};

export default FilterOption;
