import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { BankInfo } from 'app/shared/model/request-withdraw.model';
import { ApiKey } from 'app/entities/system-bank/system-bank-update';

interface ListBankProps {
  listApiKey: ApiKey[];
  onCallbackBanks: (bank: ApiKey) => void;
  setSelectedAccounts: React.Dispatch<React.SetStateAction<ApiKey>>;
  selectedAccounts: ApiKey;
}

const ApiKeyDropdownList: React.FC<ListBankProps> = ({ listApiKey, onCallbackBanks, selectedAccounts, setSelectedAccounts }) => {
  const listBanks: ApiKey[] = [...listApiKey];
  const handleAccountSelection = (selectedItems: ApiKey) => {
    onCallbackBanks(selectedItems);
    setSelectedAccounts(selectedItems);
  };
  return (
    <Stack>
      <Autocomplete
        key={selectedAccounts?.key}
        options={listBanks}
        defaultValue={selectedAccounts}
        getOptionLabel={option => option?.deviceName ?? option?.key}
        value={selectedAccounts}
        onChange={(_, newValue) => handleAccountSelection(newValue)}
        renderInput={params => {
          return <TextField {...params} label="Select Device" />;
        }}
      />
    </Stack>
  );
};

export default ApiKeyDropdownList;
