export const resetFilter = (setPaginationState, paginationState, setFilterMode, dispatch, getEntities) => {
  setPaginationState({
    ...paginationState,
    activePage: 1,
  });
  setFilterMode(false);
  dispatch(
    getEntities({
      page: 0,
      size: paginationState.itemsPerPage,
      sort: `${paginationState.sort},${paginationState.order}`,
    })
  );
};

export const sortFilterEntities = (values, isFilterMode, setFilterMode, setFilterValue, dispatch, paginationState, getFilterData) => {
  if (!isFilterMode) {
    setFilterMode(true);
    setFilterValue(values);
  }
  dispatch(
    getFilterData({
      page: paginationState.activePage - 1,
      size: paginationState.itemsPerPage,
      accountName: values?.accountName,
      accountNumber: values?.accountNumber,
      bankName: values?.bankName,
      phoneNumber: values?.phoneNumber,
      deviceName: values?.deviceName,
    })
  );
};
