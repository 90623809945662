

import React, { useEffect, useState } from 'react';


import { MdNetworkCell } from 'react-icons/md'
import { useAppSelector } from 'app/config/store';
import CardOutline from '../Card';
import axios from 'axios';
import { ReportSystemBank } from '../modules/report.module';
import LoadingIndicator from 'app/common/loadingIndicator';

const RealTimeData: React.FC = () => {
  const [reportRealTimeData, setReportRealTime] = useState<ReportSystemBank>({} as ReportSystemBank);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('api/get-report');
        setReportRealTime(response.data);
        setLoading(false);

      } catch (error) {

        setLoading(false);
      }
    }
    fetchData();
  }, []);
  const reportData = useAppSelector(state => state.administration?.report);
  const hasReportData = Object.keys(reportData)?.length === 0
  const data = hasReportData ? reportRealTimeData : reportData
  return (
    <>
      {loading ? (
        <div >
          <LoadingIndicator />
        </div>
      ) : (
        <div className="dashboard">
          <CardOutline currentAmount={data?.currentAmount}
            icon={<MdNetworkCell className='icon' />} totalDeposit={data?.totalDeposit}
            listSystemBankDeposit={data?.depositableAmount?.listSystemBank?.length}
            totalMoneyDeposit={data?.depositableAmount?.totalMoney}
            listSystemBankWithDrawn={data?.withdrawableAmount?.listSystemBank?.length}
            totalMoneyWithDrawn={data?.withdrawableAmount?.totalMoney}
            totalWithdrawn={data?.totalWithdrawn}
            totalDepositErrorInDay={data?.totalDepositErrorInDay}
          />

        </div>
      )}
    </>

  );
};

export default RealTimeData;
