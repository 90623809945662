import dayjs from 'dayjs';

export interface ISystemSetting {
  id?: string;
  depositFee?: number | null;
  withdrawFee?: number | null;
  maxDeposit?: number | null;
  maxWithdraw?: number | null;
  threshold?: number | null;
  createdDate?: string | null;
  updatedDate?: string | null;
  maxDepositInDay?: number | null;
  maxWithdrawInDay?: number | null;
}

export const defaultValue: Readonly<ISystemSetting> = {};
