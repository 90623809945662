import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DepositTransaction from './deposit-transaction';
import DepositTransactionDetail from './deposit-transaction-detail';
import DepositTransactionUpdate from './deposit-transaction-update';
import DepositTransactionDeleteDialog from './deposit-transaction-delete-dialog';

const DepositTransactionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DepositTransaction />} />
    <Route path="new" element={<DepositTransactionUpdate />} />
    <Route path=":id">
      <Route index element={<DepositTransactionDetail />} />
      <Route path="edit" element={<DepositTransactionUpdate />} />
      <Route path="delete" element={<DepositTransactionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DepositTransactionRoutes;
