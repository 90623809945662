import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IApiKey, defaultValue } from 'app/shared/model/api-key.model';

const initialState: EntityState<IApiKey> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

export interface IApiKeyQuery {
  page: number;
  size: number;
  deviceName?: string;
}

const apiUrl = 'api/api-keys';
const apiExtendUrl = 'api/api-keys-filter';

// Actions

export const getEntities = createAsyncThunk('apiKey/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?cacheBuster=${new Date().getTime()}`;
  return axios.get<IApiKey[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'apiKey/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IApiKey>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getFilterData = createAsyncThunk('apiKey/fetch_filter_data_list', async ({ page, size, deviceName }: IApiKeyQuery) => {
  const requestUrl = `${apiExtendUrl}/${`?page=${page}&size=${size}${deviceName ? `&deviceName=${deviceName}` : ''}`}`;
  return axios.get<IApiKey[]>(requestUrl);
});

export const createEntity = createAsyncThunk(
  'apiKey/create_entity',
  async (entity: IApiKey, thunkAPI) => {
    const result = await axios.post<IApiKey>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'apiKey/update_entity',
  async (entity: IApiKey, thunkAPI) => {
    const result = await axios.put<IApiKey>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'apiKey/partial_update_entity',
  async (entity: IApiKey, thunkAPI) => {
    const result = await axios.patch<IApiKey>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'apiKey/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IApiKey>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ApiKeySlice = createEntitySlice({
  name: 'apiKey',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(getFilterData), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getFilterData), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ApiKeySlice.actions;

// Reducer
export default ApiKeySlice.reducer;
