import React, { useEffect, useState, useId } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { getSortState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { decryptAES, encryptAES, getEntities, getFilterData } from './system-bank.reducer';
import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import '../../../app/common/style/index.scss';
import { resetFilter } from '../../shared/util/filter-utils';
import FilterOption, { IFormFilterField } from '../../common/FilterOption/FilterOption';
import { formatCurrency } from 'app/entities/request-management/request-select/request-tag';
import ShowHidePwd from '../../common/ShowHidePwd';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export const SystemBank = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [isFilterMode, setFilterMode] = useState(false);
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', 'desc'), location.search)
  );

  const formField = [
    { type: 'text', name: 'accountNumber', label: 'Account Number', placeholder: 'Account Number' },
    { type: 'text', name: 'accountName', label: 'Account Name', placeholder: 'Account Name' },
    { type: 'text', name: 'bankName', label: 'Bank Name', placeholder: 'Bank Name' },
    { type: 'text', name: 'phoneNumber', label: 'Phone Number', placeholder: 'Phone Number' },
    { type: 'text', name: 'deviceName', label: 'Device Name', placeholder: 'Device Name' },
  ];

  const systemBankList = useAppSelector(state => state.systemBank.entities);
  const loading = useAppSelector(state => state.systemBank.loading);
  const totalItems = useAppSelector(state => state.systemBank.totalItems);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    if (!isFilterMode) {
      sortEntities();
    }
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    resetFilter(setPaginationState, paginationState, setFilterMode, dispatch, getEntities);
    sortEntities();
  };

  const rows: GridRowsProp = systemBankList.map(bank => ({
    id: bank?.id,
    accountNumber: bank?.accountNumber,
    accountName: bank?.accountName,
    bankName: bank?.bankName,
    balance: bank?.balance,
    phoneNumber: bank?.phoneNumber,
    isDeleted: bank?.isDeleted,
    createdDate: bank?.createdDate,
    updatedDate: bank?.updatedDate,
  }));

  const columns: GridColDef[] = [
    { field: 'id' },
    { field: 'accountNumber', headerName: 'Account Number', width: 150 },
    { field: 'accountName', headerName: 'Account Name', width: 150 },
    { field: 'bankName', headerName: 'Bank Name', width: 150 },
    { field: 'balance', headerName: 'Balance', width: 150 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
    { field: 'isDeleted', headerName: 'Is Deleted', width: 150 },
    { field: 'createdDate', headerName: 'Created Date', width: 150 },
    { field: 'updatedDate', headerName: 'Updated Date', width: 150 },
    {
      field: 'View',
      headerClassName: 'actions',
      width: 100,
      renderCell: params => {
        return (
          <Button color="info" size="sm" data-cy="entityDetailsButton">
            <FontAwesomeIcon icon="eye" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.view">View</Translate>
            </span>
          </Button>
        );
      },
    },
    {
      field: 'Edit',
      headerClassName: 'actions',
      headerName: 'Edit',
      width: 100,
      renderCell: params => {
        return (
          <Button color="primary" size="sm" data-cy="entityEditButton">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        );
      },
    },
    {
      field: 'Delete',
      headerClassName: 'actions',
      width: 100,
      renderCell: params => {
        return (
          <Button color="danger" size="sm" data-cy="entityDeleteButton">
            <FontAwesomeIcon icon="trash" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.delete">Delete</Translate>
            </span>
          </Button>
        );
      },
    },
  ];
  return (
    <div className="main-content">
      <h2 id="system-bank-heading" data-cy="SystemBankHeading">
        <Translate contentKey="cesPaymentGatewayApp.systemBank.home.title">System Banks</Translate>
        <div>
          <FilterOption
            isFilterMode={isFilterMode}
            setFilterMode={setFilterMode}
            setPaginationState={setPaginationState}
            paginationState={paginationState}
            getEntities={getEntities}
            getFilterData={getFilterData}
            labelWidth={130}
            fieldFilters={formField as IFormFilterField[]}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="cesPaymentGatewayApp.systemBank.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/system-bank/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="cesPaymentGatewayApp.systemBank.home.createLabel">Create new System Bank</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {systemBankList && systemBankList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('accountNumber')}>
                  <Translate contentKey="cesPaymentGatewayApp.systemBank.accountNumber">Account Number</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('accountName')}>
                  <Translate contentKey="cesPaymentGatewayApp.systemBank.accountName">Account Name</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('bankName')}>
                  <Translate contentKey="cesPaymentGatewayApp.systemBank.bankName">Bank Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('balance')}>
                  <Translate contentKey="cesPaymentGatewayApp.systemBank.balance">Balance</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('phoneNumber')}>
                  <Translate contentKey="cesPaymentGatewayApp.systemBank.phoneNumber">Phone Number</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('userWeb')}>
                  <Translate contentKey="cesPaymentGatewayApp.systemBank.userWeb">User Web</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('pwdWeb')}>
                  <Translate contentKey="cesPaymentGatewayApp.systemBank.pwdWeb">Pwd Web</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('userApp')}>
                  <Translate contentKey="cesPaymentGatewayApp.systemBank.userApp">User App</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('pwdApp')}>
                  <Translate contentKey="cesPaymentGatewayApp.systemBank.pwdApp">Pwd App</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('deviceName')}>
                  <Translate contentKey="cesPaymentGatewayApp.systemBank.deviceName">Device Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {systemBankList.map((systemBank, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{systemBank.accountNumber}</td>
                  <td>{systemBank.accountName}</td>
                  <td>{systemBank.bankName}</td>
                  <td>{formatCurrency(systemBank.balance)}</td>
                  <td>{systemBank.phoneNumber}</td>
                  <td>{systemBank.userWeb}</td>
                  <td>
                    <ShowHidePwd pwd={systemBank.pwdWeb} />
                  </td>
                  <td>{systemBank.userApp}</td>
                  <td>
                    <ShowHidePwd pwd={systemBank.pwdApp} />
                  </td>
                  <td>{systemBank.deviceName}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/system-bank/${systemBank.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      {isAdmin && (
                        <Button
                          tag={Link}
                          to={`/system-bank/${systemBank.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                      )}
                      {isAdmin && (
                        <Button
                          tag={Link}
                          to={`/system-bank/${systemBank.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="cesPaymentGatewayApp.systemBank.home.notFound">No System Banks found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={systemBankList && systemBankList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default SystemBank;
