import dayjs from 'dayjs';

export interface IApiKey {
  id?: string;
  key?: string | null;
  uniqueDevice?: string | null;
  deviceName?: string | null;
  isActive?: boolean | null;
  isDeleted?: boolean | null;
  createdDate?: string | null;
  updatedDate?: string | null;
}

export const defaultValue: Readonly<IApiKey> = {
  isActive: false,
  isDeleted: false,
};
