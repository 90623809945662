import { IUser } from 'app/shared/model/user.model';

export interface IBank {
  id?: string;
  accountNumber?: string | null;
  accountName?: string | null;
  bankName?: string | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IBank> = {};
