import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SystemSetting from './system-setting';
import SystemSettingDetail from './system-setting-detail';
import SystemSettingUpdate from './system-setting-update';
import SystemSettingDeleteDialog from './system-setting-delete-dialog';

const SystemSettingRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SystemSetting />} />
    <Route path="new" element={<SystemSettingUpdate />} />
    <Route path=":id">
      <Route index element={<SystemSettingDetail />} />
      <Route path="edit" element={<SystemSettingUpdate />} />
      <Route path="delete" element={<SystemSettingDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SystemSettingRoutes;
