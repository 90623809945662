import dayjs from 'dayjs';

export interface IIpWhiteList {
  id?: string;
  ip?: string | null;
  isActive?: boolean | null;
  createdDate?: string | null;
  updatedDate?: string | null;
}

export const defaultValue: Readonly<IIpWhiteList> = {
  isActive: false,
};
