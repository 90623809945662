import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './api-key.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const ApiKeyDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const apiKeyEntity = useAppSelector(state => state.apiKey.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="apiKeyDetailsHeading">
          <Translate contentKey="cesPaymentGatewayApp.apiKey.detail.title">ApiKey</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{apiKeyEntity.id}</dd>
          <dt>
            <span id="key">
              <Translate contentKey="cesPaymentGatewayApp.apiKey.key">Key</Translate>
            </span>
          </dt>
          <dd>{apiKeyEntity.key}</dd>
          <dt>
            <span id="uniqueDevice">
              <Translate contentKey="cesPaymentGatewayApp.apiKey.uniqueDevice">Unique Device</Translate>
            </span>
          </dt>
          <dd>{apiKeyEntity.uniqueDevice}</dd>
          <dt>
            <span id="deviceName">
              <Translate contentKey="cesPaymentGatewayApp.apiKey.deviceName">Device Name</Translate>
            </span>
          </dt>
          <dd>{apiKeyEntity.deviceName}</dd>
          <dt>
            <span id="isActive">
              <Translate contentKey="cesPaymentGatewayApp.apiKey.isActive">Is Active</Translate>
            </span>
          </dt>
          <dd>{apiKeyEntity.isActive ? 'true' : 'false'}</dd>
          <dt>
            <span id="isDeleted">
              <Translate contentKey="cesPaymentGatewayApp.apiKey.isDeleted">Is Deleted</Translate>
            </span>
          </dt>
          <dd>{apiKeyEntity.isDeleted ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="cesPaymentGatewayApp.apiKey.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>{apiKeyEntity.createdDate ? <TextFormat value={apiKeyEntity.createdDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="cesPaymentGatewayApp.apiKey.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>{apiKeyEntity.updatedDate ? <TextFormat value={apiKeyEntity.updatedDate} type="date" format={APP_DATE_FORMAT} /> : null}</dd>
        </dl>
        <Button tag={Link} to="/api-key" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {isAdmin && (
          <Button tag={Link} to={`/api-key/${apiKeyEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default ApiKeyDetail;
