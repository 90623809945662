import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { BankStatus } from 'app/shared/model/enumerations/bank-status.model';

export interface IRequestWithdraw {
  id?: string;
  amount?: number | null;
  status?: BankStatus | null;
  confirmedId?: string | null;
  bankName?: string | null;
  branch?: string | null;
  swiftCode?: string | null;
  bankAccountName?: string | null;
  bankAccountNumber?: string | null;
  isDeleted?: boolean | null;
  createdDate?: string | null;
  updatedDate?: string | null;
  personInCharge?: IUser | null;
}
export interface BankInfo {
  accountNumber?: string;
  id?: string;
  accountName?: string;
  bankName?: string;
  balance?: number;
  amountWithdrawn?: number;
  phoneNumber?: string;
  isDeleted?: boolean;
  createdDate?: string;
  updatedDate?: string;
}
export interface IRequestWithdrawTransaction {
  listSystemBank?: BankInfo[];
  requestWithdraw?: IRequestWithdraw;
  skipCallback?: boolean
}

export const defaultValue: Readonly<IRequestWithdraw> = {
  isDeleted: false,
};
