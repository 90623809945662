import dayjs from 'dayjs';
import { IRequestWithdraw } from 'app/shared/model/request-withdraw.model';
import { ITransfer } from 'app/shared/model/transfer.model';
import { BankStatus } from 'app/shared/model/enumerations/bank-status.model';

export interface IWithdrawTransaction {
  id?: string;
  orderId?: string | null;
  recipientBankAccountNumber?: string | null;
  status?: BankStatus | null;
  isDeleted?: boolean | null;
  createdDate?: string | null;
  updatedDate?: string | null;
  requestWithdraw?: IRequestWithdraw | null;
  transfers?: ITransfer[] | null;
}

export const defaultValue: Readonly<IWithdrawTransaction> = {
  isDeleted: false,
};
