import React, { useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Row, Col, Form } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import './style.scss';
import { toast } from 'react-toastify';


export interface ILoginModalProps {
  loginError: boolean;
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
}


const LoginForm = (props: ILoginModalProps) => {
  const login = ({ username, password, rememberMe }) => {
    props.handleLogin(username, password, rememberMe);
  };

  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields }
  } = useForm({ mode: 'onTouched' });

  const { loginError } = props;

  const handleLoginSubmit = e => {
    if (loginError) {
      toast.error('Username or password is wrong');
    }
    handleSubmit(login)(e);
  };

  return (
    <Form onSubmit={handleLoginSubmit}>
      <ModalBody className="container-form">
        <Row className="login-form">
          <Col md="12">
            {/* {loginError ? (
                <Alert color="danger" data-cy="loginError">
                  <Translate contentKey="login.messages.error.authentication">
                    <strong>Failed to sign in!</strong> Please check your credentials and try again.
                  </Translate>
                </Alert>
              ) : null} */}
          </Col>
          <Col md="3" className="card-form">
            <h4 className="login-title">Login</h4>

            <ValidatedField
              name="username"
              label={translate('global.form.username.label')}
              placeholder={translate('global.form.username.placeholder')}
              required
              autoFocus
              data-cy="username"
              validate={{ required: 'Username cannot be empty!' }}
              register={register}
              error={errors.username}
              isTouched={touchedFields.username}
            />
            <ValidatedField
              name="password"
              type="password"
              label={translate('login.form.password')}
              placeholder={translate('login.form.password.placeholder')}
              required
              data-cy="password"
              validate={{ required: 'Password cannot be empty!' }}
              register={register}
              error={errors.password}
              isTouched={touchedFields.password}
            />

            <Col className="row-button">
              <Button color="primary" type="submit" data-cy="submit">
                <span>LOG IN</span>
              </Button>
            </Col>
          </Col>
        </Row>
      </ModalBody>
    </Form>
  );
};

export default LoginForm;
