import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { ISystemSetting } from 'app/shared/model/system-setting.model';
import { getEntities } from './system-setting.reducer';
import { formatCurrency } from 'app/entities/request-management/request-select/request-tag';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const SystemSetting = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const systemSettingList = useAppSelector(state => state.systemSetting.entities);
  const loading = useAppSelector(state => state.systemSetting.loading);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    dispatch(getEntities({}));
  }, []);

  const handleSyncList = () => {
    dispatch(getEntities({}));
  };

  return (
    <div>
      <h2 id="system-setting-heading" data-cy="SystemSettingHeading">
        <Translate contentKey="cesPaymentGatewayApp.systemSetting.home.title">System Settings</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="cesPaymentGatewayApp.systemSetting.home.refreshListLabel">Refresh List</Translate>
          </Button>
          {!systemSettingList.length && (
            <Link to="/system-setting/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
              <FontAwesomeIcon icon="plus" />
              &nbsp;
              <Translate contentKey="cesPaymentGatewayApp.systemSetting.home.createLabel">Create new System Setting</Translate>
            </Link>
          )}{' '}
        </div>
      </h2>
      <div className="table-responsive">
        {systemSettingList && systemSettingList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.systemSetting.depositFee">Deposit Fee</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.systemSetting.withdrawFee">Withdraw Fee</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.systemSetting.maxDeposit">Max Deposit</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.systemSetting.maxWithdraw">Max Withdraw</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.systemSetting.threshold">Threshold</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.systemSetting.createdDate">Created Date</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.systemSetting.updatedDate">Updated Date</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.systemSetting.maxDepositInDay">Max Deposit In Day</Translate>
                </th>
                <th>
                  <Translate contentKey="cesPaymentGatewayApp.systemSetting.maxWithdrawInDay">Max Withdraw In Day</Translate>
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {systemSettingList.map((systemSetting, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>{systemSetting.depositFee}</td>
                  <td>{systemSetting.withdrawFee}</td>
                  <td>{formatCurrency(systemSetting?.maxDeposit)}</td>
                  <td>{formatCurrency(systemSetting?.maxWithdraw)}</td>
                  <td>{formatCurrency(systemSetting?.threshold)}</td>
                  <td>
                    {systemSetting.createdDate ? (
                      <TextFormat type="date" value={systemSetting.createdDate} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>
                    {systemSetting.updatedDate ? (
                      <TextFormat type="date" value={systemSetting.updatedDate} format={APP_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{formatCurrency(systemSetting.maxDepositInDay)}</td>
                  <td>{formatCurrency(systemSetting.maxWithdrawInDay)}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/system-setting/${systemSetting.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      {isAdmin && (
                        <Button
                          tag={Link}
                          to={`/system-setting/${systemSetting.id}/edit`}
                          color="primary"
                          size="sm"
                          data-cy="entityEditButton"
                        >
                          <FontAwesomeIcon icon="pencil-alt" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span>
                        </Button>
                      )}
                      {isAdmin && (
                        <Button
                          tag={Link}
                          to={`/system-setting/${systemSetting.id}/delete`}
                          color="danger"
                          size="sm"
                          data-cy="entityDeleteButton"
                        >
                          <FontAwesomeIcon icon="trash" />{' '}
                          <span className="d-none d-md-inline">
                            <Translate contentKey="entity.action.delete">Delete</Translate>
                          </span>
                        </Button>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="cesPaymentGatewayApp.systemSetting.home.notFound">No System Settings found</Translate>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default SystemSetting;
