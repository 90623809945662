import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { BankInfo } from 'app/shared/model/request-withdraw.model';

interface ListBankProps {
  listBank: BankInfo[];
  onCallbackBanks: (listBank: BankInfo[]) => void;
  amount: number;
  maxWithdraw: number;
  maxWithdrawInDay: number;
}
export function formatCurrency(number?: number): string {
  return number?.toLocaleString('vi-VN', { style: 'currency', currency: 'VND' });
}

const TagRequest: React.FC<ListBankProps> = ({ listBank, onCallbackBanks, amount, maxWithdraw, maxWithdrawInDay }) => {
  const [selectedAccounts, setSelectedAccounts] = useState<BankInfo[]>([]);
  const listBanks: BankInfo[] = listBank.filter(
    item => item.balance > 0 && (item.balance < maxWithdrawInDay ? item.balance : maxWithdrawInDay) - item.amountWithdrawn > 0
  );

  const getCurrentAmount = () => {
    const totalBalance = selectedAccounts.reduce((accumulator, currentItem) => {
      const balanceToAdd = (currentItem.balance < maxWithdrawInDay ? currentItem.balance : maxWithdrawInDay) - currentItem.amountWithdrawn;
      return accumulator + balanceToAdd;
    }, 0);
    return totalBalance > amount ? amount : totalBalance;
  };
  const handleAccountSelection = (selectedItems: BankInfo[]) => {
    onCallbackBanks(selectedItems);
    setSelectedAccounts(selectedItems);
  };
  return (
    <Stack>
      <Autocomplete
        multiple
        options={listBanks}
        getOptionLabel={option =>
          `${option.bankName} - ${option.accountName} - ${option.accountNumber} - Balance(${formatCurrency(
            option.balance
          )}) - Withdraw Available(${formatCurrency(
            (option.balance < maxWithdrawInDay ? option.balance : maxWithdrawInDay) - option.amountWithdrawn
          )})`
        }
        getOptionDisabled={option => selectedAccounts.includes(option as BankInfo)}
        value={selectedAccounts}
        onChange={(_, newValue) => handleAccountSelection(newValue)}
        renderInput={params => <TextField {...params} label="Select Bank" />}
      />
      <div>
        {selectedAccounts.length > 0 && (
          <p style={{ marginTop: '10px' }}>
            Amount: <span style={{ color: 'green' }}>{`${formatCurrency(getCurrentAmount())}/${formatCurrency(amount)}`}</span>
          </p>
        )}
        <h4>Selected Accounts:</h4>
        <ul>
          {selectedAccounts.map(account => (
            <li key={account.id}>
              Account Name: {account.accountName} - Account Number: {account.accountNumber} - Balance: {formatCurrency(account.balance)}
              {/*<span className='delete-bank' onClick={() => handleDelete(account.id)}>x</span>*/}
            </li>
          ))}
        </ul>
      </div>
    </Stack>
  );
};

export default TagRequest;
