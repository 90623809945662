import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import DepositError from './deposit-error';
import DepositErrorDetail from './deposit-error-detail';
import DepositErrorUpdate from './deposit-error-update';
import DepositErrorDeleteDialog from './deposit-error-delete-dialog';

const DepositErrorRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<DepositError />} />
    <Route path="new" element={<DepositErrorUpdate />} />
    <Route path=":id">
      <Route index element={<DepositErrorDetail />} />
      <Route path="edit" element={<DepositErrorUpdate />} />
      <Route path="delete" element={<DepositErrorDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default DepositErrorRoutes;
