import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ISystemBank, defaultValue } from 'app/shared/model/system-bank.model';
import * as CryptoJS from 'crypto-js';

const initialState: EntityState<ISystemBank> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export interface ISystemBankQuery {
  page: number;
  size: number;
  accountName?: string;
  accountNumber?: string;
  bankName?: string;
  phoneNumber?: string;
  deviceName?: string;
}

const apiUrl = 'api/system-banks';
const apiExtendUrl = 'api/system-banks-extend';

// Actions

export const getEntities = createAsyncThunk('systemBank/fetch_entity_list', async ({ page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}cacheBuster=${new Date().getTime()}`;
  return axios.get<ISystemBank[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'systemBank/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ISystemBank>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const getFilterData = createAsyncThunk(
  'systemBank/fetch_filter_data_list',
  async ({ page, size, accountName, accountNumber, bankName, phoneNumber, deviceName }: ISystemBankQuery) => {
    const requestUrl = `${apiExtendUrl}/${`?page=${page}&size=${size}${accountName ? `&accountName=${accountName}` : ''}${
      accountNumber ? `&accountNumber=${accountNumber}` : ''
    }${bankName ? `&bankName=${bankName}` : ''}${phoneNumber ? `&phoneNumber=${phoneNumber}` : ''}${
      deviceName ? `&deviceName=${deviceName}` : ''
    }`}`;
    return axios.get<ISystemBank[]>(requestUrl);
  }
);

export const createEntity = createAsyncThunk(
  'systemBank/create_entity',
  async (entity: ISystemBank, thunkAPI) => {
    const result = await axios.post<ISystemBank>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'systemBank/update_entity',
  async (entity: ISystemBank, thunkAPI) => {
    const result = await axios.put<ISystemBank>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'systemBank/partial_update_entity',
  async (entity: ISystemBank, thunkAPI) => {
    const result = await axios.patch<ISystemBank>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'systemBank/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ISystemBank>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const SystemBankSlice = createEntitySlice({
  name: 'systemBank',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(getFilterData), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getFilterData), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const encryptAES = (plaintext: string): string =>{
  if(plaintext == undefined || plaintext === "") return undefined;
  const ciphertext: CryptoJS.WordArray = CryptoJS.AES.encrypt(plaintext, 'gVvH6BVUAPJzUo12MIB9vwUCfQaXSCkzWocM0k8USF9u85vj');
  return ciphertext.toString();
}

export const decryptAES = (ciphertext: string): string  =>{
  if(ciphertext == undefined || ciphertext === "") return undefined
  const bytes: CryptoJS.WordArray = CryptoJS.AES.decrypt(ciphertext, 'gVvH6BVUAPJzUo12MIB9vwUCfQaXSCkzWocM0k8USF9u85vj');
  return bytes.toString(CryptoJS.enc.Utf8);
}

export const { reset } = SystemBankSlice.actions;

// Reducer
export default SystemBankSlice.reducer;
