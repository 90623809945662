import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import IpWhiteList from './ip-white-list';
import IpWhiteListDetail from './ip-white-list-detail';
import IpWhiteListUpdate from './ip-white-list-update';
import IpWhiteListDeleteDialog from './ip-white-list-delete-dialog';

const IpWhiteListRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<IpWhiteList />} />
    <Route path="new" element={<IpWhiteListUpdate />} />
    <Route path=":id">
      <Route index element={<IpWhiteListDetail />} />
      <Route path="edit" element={<IpWhiteListUpdate />} />
      <Route path="delete" element={<IpWhiteListDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default IpWhiteListRoutes;
