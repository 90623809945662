import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { IRequestWithdraw } from 'app/shared/model/request-withdraw.model';
import { BankStatus } from 'app/shared/model/enumerations/bank-status.model';
import { getEntity, updateEntity, createEntity, reset } from './request-withdraw.reducer';
enum BankStatusRequestWithdraw {
  OPEN = 'OPEN',
}
export const RequestWithdrawUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const requestWithdrawEntity = useAppSelector(state => state.requestWithdraw.entity);
  const loading = useAppSelector(state => state.requestWithdraw.loading);
  const updating = useAppSelector(state => state.requestWithdraw.updating);
  const updateSuccess = useAppSelector(state => state.requestWithdraw.updateSuccess);
  const bankStatusValues = Object.keys(BankStatusRequestWithdraw);

  const handleClose = () => {
    navigate('/request-withdraw' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.updatedDate = convertDateTimeToServer(values.updatedDate);

    const entity = {
      ...requestWithdrawEntity,
      ...values,
      personInCharge: users.find(it => it.id.toString() === values.personInCharge.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          updatedDate: displayDefaultDateTime(),
        }
      : {
          status: 'COMPLETED',
          ...requestWithdrawEntity,
          createdDate: convertDateTimeFromServer(requestWithdrawEntity.createdDate),
          updatedDate: convertDateTimeFromServer(requestWithdrawEntity.updatedDate),
          personInCharge: requestWithdrawEntity?.personInCharge?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="cesPaymentGatewayApp.requestWithdraw.home.createOrEditLabel" data-cy="RequestWithdrawCreateUpdateHeading">
            <Translate contentKey="cesPaymentGatewayApp.requestWithdraw.home.createOrEditLabel">Create or edit a RequestWithdraw</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="request-withdraw-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('cesPaymentGatewayApp.requestWithdraw.amount')}
                id="request-withdraw-amount"
                name="amount"
                data-cy="amount"
                type="text"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.requestWithdraw.status')}
                id="request-withdraw-status"
                name="status"
                data-cy="status"
                type="select"
              >
                {bankStatusValues.map(bankStatus => (
                  <option value={bankStatus} key={bankStatus}>
                    {translate('cesPaymentGatewayApp.BankStatus.' + bankStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('cesPaymentGatewayApp.requestWithdraw.confirmedId')}
                id="request-withdraw-confirmedId"
                name="confirmedId"
                data-cy="confirmedId"
                type="text"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.requestWithdraw.bankName')}
                id="request-withdraw-bankName"
                name="bankName"
                data-cy="bankName"
                type="text"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.requestWithdraw.branch')}
                id="request-withdraw-branch"
                name="branch"
                data-cy="branch"
                type="text"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.requestWithdraw.swiftCode')}
                id="request-withdraw-swiftCode"
                name="swiftCode"
                data-cy="swiftCode"
                type="text"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.requestWithdraw.bankAccountName')}
                id="request-withdraw-bankAccountName"
                name="bankAccountName"
                data-cy="bankAccountName"
                type="text"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.requestWithdraw.bankAccountNumber')}
                id="request-withdraw-bankAccountNumber"
                name="bankAccountNumber"
                data-cy="bankAccountNumber"
                type="text"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.requestWithdraw.isDeleted')}
                id="request-withdraw-isDeleted"
                name="isDeleted"
                data-cy="isDeleted"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.requestWithdraw.createdDate')}
                id="request-withdraw-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.requestWithdraw.updatedDate')}
                id="request-withdraw-updatedDate"
                name="updatedDate"
                data-cy="updatedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                id="request-withdraw-personInCharge"
                name="personInCharge"
                data-cy="personInCharge"
                label={translate('cesPaymentGatewayApp.requestWithdraw.personInCharge')}
                type="select"
              >
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/request-withdraw" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RequestWithdrawUpdate;
