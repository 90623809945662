import React, { useMemo } from 'react';
import { DualAxes } from '@ant-design/plots';
import './style.scss';
import { Deposit } from '../model';
interface TransactionProps {
  transactionDataToday: [];
  isWithDrawn: boolean;
}
const ChartTransaction: React.FC<TransactionProps> = ({ transactionDataToday, isWithDrawn }) => {
  const configDataChart =transactionDataToday?.map((item: Deposit) => ({
    day: item?.day,
    VND: item?.total,
    count: item?.count,
  }))
  const config = {
    data: [configDataChart, configDataChart],
    xField: 'day',
    yField: ['VND', 'count'],
    yAxis: {
      VND: {
        label: {
          formatter: (val: any) => `${Number(val).toLocaleString()}`,
        }
      }
    },
    xAxis: {
      label: {
        autoRotate: true,
      },
    },
    tooltip: {
      customContent: (title: string, items: any) => {
        return (
          <>
            <p style={{ marginTop: 16 }}>{title}</p>
            <ul style={{ paddingLeft: 0 }}>
              {items?.map((item: any, index: number) => {
                const { name, value, color } = item;
                return (
                  <li
                    key={item.year}
                    className="g2-tooltip-list-item"
                    data-index={index}
                    style={{ marginBottom: 4, display: 'flex', alignItems: 'center' }}
                  >
                    <span className="g2-tooltip-marker" style={{ backgroundColor: color }}></span>
                    <span
                      style={{ display: 'inline-flex', flex: 1 }}
                    >
                      <span style={{ marginRight: 16 }}>{name}:</span>
                      <span className="g2-tooltip-list-item-value">{name === 'count' ? value : `${Number(value).toLocaleString()}`}</span>
                    </span>
                  </li>
                );
              })}
            </ul>
          </>
        );
      },
    },
    slider: {
      start: 0,
      end: 0.5,
    },
    geometryOptions: [
      {
        geometry: 'column',
        color: isWithDrawn ? '#F4664A' : '#00C4CD',
      },
      {
        geometry: 'line',
        lineStyle: {
          lineWidth: 2,
        },
      },
    ],
  };

  return (
    <div className="deposit-box">
      <>
        <DualAxes {...config} />
        <div className="note-box">
          <span className="note">Note*:</span>
          <div className="note-value">
            <div className={isWithDrawn ? 'withdrawn-content' : 'deposit-content'}></div>
            <span>VND:<span style={{ fontSize: '14px', paddingLeft: '5px' }}>Total money {isWithDrawn ? 'withdrawn' : 'deposit'}  per day</span> </span>
          </div>
          <div className="note-count">
            <div className="count-content"></div>
            <span>count:<span style={{ fontSize: '14px', paddingLeft: '5px' }}>Total count {isWithDrawn ? 'withdrawn' : 'deposit'}  per day</span> </span>
          </div>
        </div>
      </>
    </div>
  );
};

export default ChartTransaction;
