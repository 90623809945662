import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './deposit-transaction.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';

export const DepositTransactionDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const depositTransactionEntity = useAppSelector(state => state.depositTransaction.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="depositTransactionDetailsHeading">
          <Translate contentKey="cesPaymentGatewayApp.depositTransaction.detail.title">DepositTransaction</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.id}</dd>
          <dt>
            <span id="amount">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.amount">Amount</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.amount}</dd>
          <dt>
            <span id="fee">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.fee">Fee</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.fee}</dd>
          <dt>
            <span id="message">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.message">Message</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.message}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.status">Status</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.status}</dd>
          <dt>
            <span id="fromBankAccount">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.fromBankAccount">From Bank Account</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.fromBankAccount}</dd>
          <dt>
            <span id="fromBankAccountName">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.fromBankAccountName">From Bank Account Name</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.fromBankAccountName}</dd>
          <dt>
            <span id="fromBankBranch">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.fromBankBranch">From Bank Branch</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.fromBankBranch}</dd>
          <dt>
            <span id="fromBank">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.fromBank">From Bank</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.fromBank}</dd>
          <dt>
            <span id="toBankAccount">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.toBankAccount">To Bank Account</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.toBankAccount}</dd>
          <dt>
            <span id="toBankAccountName">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.toBankAccountName">To Bank Account Name</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.toBankAccountName}</dd>
          <dt>
            <span id="toBankBranch">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.toBankBranch">To Bank Branch</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.toBankBranch}</dd>
          <dt>
            <span id="toBank">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.toBank">To Bank</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.toBank}</dd>
          <dt>
            <span id="isDeleted">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.isDeleted">Is Deleted</Translate>
            </span>
          </dt>
          <dd>{depositTransactionEntity.isDeleted ? 'true' : 'false'}</dd>
          <dt>
            <span id="createdDate">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.createdDate">Created Date</Translate>
            </span>
          </dt>
          <dd>
            {depositTransactionEntity.createdDate ? (
              <TextFormat value={depositTransactionEntity.createdDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="updatedDate">
              <Translate contentKey="cesPaymentGatewayApp.depositTransaction.updatedDate">Updated Date</Translate>
            </span>
          </dt>
          <dd>
            {depositTransactionEntity.updatedDate ? (
              <TextFormat value={depositTransactionEntity.updatedDate} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <Translate contentKey="cesPaymentGatewayApp.depositTransaction.recipientBankAccount">Recipient Bank Account</Translate>
          </dt>
          <dd>{depositTransactionEntity.recipientBankAccount ? depositTransactionEntity.recipientBankAccount.id : ''}</dd>
          <dt>
            <Translate contentKey="cesPaymentGatewayApp.depositTransaction.user">User</Translate>
          </dt>
          <dd>{depositTransactionEntity.user ? depositTransactionEntity.user.login : ''}</dd>
          <dt>
            <Translate contentKey="cesPaymentGatewayApp.depositTransaction.updatedUser">Updated User</Translate>
          </dt>
          <dd>{depositTransactionEntity.updatedUser ? depositTransactionEntity.updatedUser.login : ''}</dd>
        </dl>
        <Button tag={Link} to="/deposit-transaction" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {isAdmin && (
          <Button tag={Link} to={`/deposit-transaction/${depositTransactionEntity.id}/edit`} replace color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
        )}
      </Col>
    </Row>
  );
};

export default DepositTransactionDetail;
