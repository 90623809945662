import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Regex from './regex';
import RegexDetail from './regex-detail';
import RegexUpdate from './regex-update';
import RegexDeleteDialog from './regex-delete-dialog';

const RegexRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Regex />} />
    <Route path="new" element={<RegexUpdate />} />
    <Route path=":id">
      <Route index element={<RegexDetail />} />
      <Route path="edit" element={<RegexUpdate />} />
      <Route path="delete" element={<RegexDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RegexRoutes;
