import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, decryptAES, encryptAES, getEntity, reset, updateEntity } from './system-bank.reducer';
import { getEntities as getAllApiKey } from 'app/entities/api-key/api-key.reducer';
import ApiKeyDropdownList from 'app/entities/system-bank/api-key-select/api-key-dropdown-list';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export interface ApiKey {
  id?: string;
  key?: string;
  uniqueDevice?: string;
  deviceName?: string;
  isActive?: boolean;
  isDeleted?: boolean;
  createdDate?: string;
  updatedDate?: string;
}

export const SystemBankUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const systemBankEntity = useAppSelector(state => state.systemBank.entity);
  const loading = useAppSelector(state => state.systemBank.loading);
  const updating = useAppSelector(state => state.systemBank.updating);
  const updateSuccess = useAppSelector(state => state.systemBank.updateSuccess);
  const apiKeyList = useAppSelector(state => state.apiKey.entities);
  const [selectedApiKey, setSelectedApiKey] = useState<ApiKey>();
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const handleClose = () => {
    navigate('/system-bank' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getAllApiKey({}));
  }, []);

  useEffect(() => {
    if (systemBankEntity && apiKeyList) {
      const apiKey = apiKeyList.find((item: ApiKey) => item?.deviceName === systemBankEntity?.deviceName);
      setSelectedApiKey(apiKey);
    }
  }, [systemBankEntity, apiKeyList]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.updatedDate = convertDateTimeToServer(values.updatedDate);
    values.pwdApp = encryptAES(values.pwdApp);
    values.pwdWeb = encryptAES(values.pwdWeb);

    let entity = {
      ...systemBankEntity,
      ...values,
    };

    if (selectedApiKey) {
      entity = {
        ...entity,
        deviceName: selectedApiKey.deviceName,
      };
    }

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          updatedDate: displayDefaultDateTime(),
        }
      : {
          ...systemBankEntity,
          pwdApp: decryptAES(systemBankEntity.pwdApp),
          pwdWeb: decryptAES(systemBankEntity.pwdWeb),
          createdDate: convertDateTimeFromServer(systemBankEntity.createdDate),
          updatedDate: convertDateTimeFromServer(systemBankEntity.updatedDate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="cesPaymentGatewayApp.systemBank.home.createOrEditLabel" data-cy="SystemBankCreateUpdateHeading">
            <Translate contentKey="cesPaymentGatewayApp.systemBank.home.createOrEditLabel">Create or edit a SystemBank</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField
                label={translate('cesPaymentGatewayApp.systemBank.accountNumber')}
                id="system-bank-accountNumber"
                name="accountNumber"
                data-cy="accountNumber"
                type="text"
                required={true}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.systemBank.accountName')}
                id="system-bank-accountName"
                name="accountName"
                data-cy="accountName"
                type="text"
                required={true}
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.systemBank.bankName')}
                id="system-bank-bankName"
                name="bankName"
                data-cy="bankName"
                type="text"
                required={true}
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.systemBank.balance')}
                id="system-bank-balance"
                name="balance"
                data-cy="balance"
                type="number"
                required={true}
                disabled={!isNew && !isAdmin}
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.systemBank.phoneNumber')}
                id="system-bank-phoneNumber"
                name="phoneNumber"
                data-cy="phoneNumber"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.systemBank.bankBranch')}
                id="system-bank-bankBranch"
                name="bankBranch"
                data-cy="bankBranch"
                type="text"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.systemBank.userWeb')}
                id="system-bank-userWeb"
                name="userWeb"
                data-cy="userWeb"
                type="text"
                validate={{
                  maxLength: {
                    value: 250,
                    message: translate('entity.validation.maxlength', { max: 250 }),
                  },
                }}
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.systemBank.pwdWeb')}
                id="system-bank-pwdWeb"
                name="pwdWeb"
                data-cy="pwdWeb"
                type="text"
                validate={{
                  maxLength: {
                    value: 250,
                    message: translate('entity.validation.maxlength', { max: 250 }),
                  },
                }}
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.systemBank.userApp')}
                id="system-bank-userApp"
                name="userApp"
                data-cy="userApp"
                type="text"
                validate={{
                  maxLength: {
                    value: 250,
                    message: translate('entity.validation.maxlength', { max: 250 }),
                  },
                }}
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.systemBank.pwdApp')}
                id="system-bank-pwdApp"
                name="pwdApp"
                data-cy="pwdApp"
                type="text"
                validate={{
                  maxLength: {
                    value: 250,
                    message: translate('entity.validation.maxlength', { max: 250 }),
                  },
                }}
              />
              {apiKeyList && (
                <div style={{ margin: '10px 0px 20px 0px' }}>
                  <label style={{ marginBottom: '10px' }}>{translate('cesPaymentGatewayApp.systemBank.deviceName')}</label>
                  <ApiKeyDropdownList
                    listApiKey={apiKeyList}
                    onCallbackBanks={apiKey => {
                      setSelectedApiKey(apiKey);
                    }}
                    selectedAccounts={selectedApiKey}
                    setSelectedAccounts={setSelectedApiKey}
                  />
                </div>
              )}
              <ValidatedField
                label={translate('cesPaymentGatewayApp.systemBank.amountWithdrawn')}
                id="system-bank-amountWithdrawn"
                name="amountWithdrawn"
                data-cy="amountWithdrawn"
                type="text"
                disabled={true}
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.systemBank.amountDeposit')}
                id="system-bank-amountDeposit"
                name="amountDeposit"
                data-cy="amountDeposit"
                type="text"
                disabled={true}
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/system-bank" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SystemBankUpdate;
