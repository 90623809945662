import React, { useEffect, useState } from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Grid } from '@mui/material';
import { formatCurrency } from 'app/common/validate';
import { useAppSelector } from 'app/config/store';
import { ReportSystemBank } from '../../modules/report.module';
import axios from 'axios';
import LoadingIndicator from 'app/common/loadingIndicator';
import '../../../home/home.scss'

export default function WithDrawnAmount() {
  const [reportRealTimeData, setReportRealTime] = useState<ReportSystemBank>({} as ReportSystemBank);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchReportRealTimeData() {
      try {
        const response = await axios.get('api/get-report');
        setReportRealTime(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }

    fetchReportRealTimeData();
  }, []);

  const reportData = useAppSelector(state => state?.administration?.report);
  const hasReportData = Object.keys(reportData)?.length === 0;
  const data = hasReportData ? reportRealTimeData : reportData;

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'Account Name', headerName: 'First name', width: 130 },
    { field: 'accountNumber', headerName: 'Account Number', width: 150 },
    { field: 'amountDeposit', headerName: 'Amount Deposit', width: 150 },
    { field: 'amountWithdrawn', headerName: 'Amount WithDrawn', width: 150 },
    { field: 'accountName', headerName: 'Account Name', width: 150 },
    { field: 'bankName', headerName: 'Bank Name', width: 150 },
    { field: 'balance', headerName: 'Balance', width: 150 },
    { field: 'phoneNumber', headerName: 'Phone Number', width: 150 },
    { field: 'isDeleted', headerName: 'Is Deleted', width: 150 },
    { field: 'createdDate', headerName: 'Created Date', width: 150 },
    { field: 'updatedDate', headerName: 'Updated Date', width: 150 },
  ];

  const rows = data?.withdrawableAmount?.listSystemBank.map(bank => ({
    id: bank?.id,
    accountNumber: bank?.accountNumber,
    accountName: bank?.accountName,
    amountDeposit: formatCurrency(bank?.amountDeposit) ? formatCurrency(bank?.amountDeposit) : '-',
    amountWithdrawn: formatCurrency(bank?.amountWithdrawn) ? formatCurrency(bank?.amountWithdrawn) : '-',
    bankName: bank?.bankName,
    balance: formatCurrency(bank?.balance),
    phoneNumber: bank?.phoneNumber,
    isDeleted: bank?.isDeleted,
    createdDate: bank?.createdDate,
    updatedDate: bank?.updatedDate,
  }));
  return (
    <div style={{ height: 400, width: '100%', marginTop: '40px', paddingBottom: '40px' }}>
      {loading ? (
        <div>
          <LoadingIndicator />
        </div>
      ) : data?.depositableAmount?.listSystemBank.length > 0 ? (
        <>
          <Grid container>
            <Grid item xs={6}>
              <p style={{ paddingRight: '20px', fontSize: '20px', fontWeight: 'bold' }}>
                <span style={{ fontWeight: 'normal' }}>Total System Bank:</span>
                {data?.depositableAmount?.listSystemBank?.length}
              </p>
            </Grid>
            <Grid item xs={6}>
              <p style={{ textAlign: 'right', paddingRight: '20px', fontSize: '20px', fontWeight: 'bold' }}>
                <span style={{ fontWeight: 'normal' }}>Total money:</span>
                {formatCurrency(data?.depositableAmount?.totalMoney)}
              </p>
            </Grid>
          </Grid>
          <DataGrid
            rows={rows}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
          />
        </>
      ) : (
        <div style={{ marginTop: '100px' }}>
          <h1 style={{ textAlign: 'center' }}>No Data Available</h1>
        </div>
      )}
    </div>
  );
}
