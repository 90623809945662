import SockJS from 'sockjs-client';
import { Link } from "react-router-dom"
import { BsArrowRightCircle } from 'react-icons/bs'
import React from "react"
import Stomp from 'webstomp-client';
import { Observable, observable } from 'rxjs';
import { Storage } from 'react-jhipster';

import { websocketActivityMessage,websocketReportData,websocketNotificationToday } from 'app/modules/administration/administration.reducer';
import { getAccount, logoutSession } from 'app/shared/reducers/authentication';
import { toast } from 'react-toastify';
import { Alert } from 'reactstrap';
import {formatCurrency} from "app/common/validate";

let stompClient = null;

let subscriber = null;
let connection: Promise<any>;
let connectedPromise: any = null;
let listener: Observable<any>;
let listenerReport: Observable<any>;
let listenerDepositTransaction: Observable<any>;
let listenerWithdrawnTransaction: Observable<any>;
let listenerNotification: Observable<any>;
let listenerObserver: any;
let listenerObserverReport: any;
let listenerObserverDepositTransaction: any;
let listenerObserverWithdrawnTransaction: any;
let listenerObserverNotification: any;
let alreadyConnectedOnce = false;

const createConnection = (): Promise<any> => new Promise(resolve => (connectedPromise = resolve));

const createListener = (): Observable<any> =>
  new Observable(observer => {
    listenerObserver = observer;
  });
const createListenerReport = (): Observable<any> =>
  new Observable(observer => {
    listenerObserverReport = observer;
  });

const createListenerDepositTransaction=():Observable<any>=>
  new Observable(observer=>{
    listenerObserverDepositTransaction=observer;
  })
const createListenerWithdrawnTransaction=():Observable<any>=>
  new Observable(observer=>{
    listenerObserverWithdrawnTransaction=observer;
  })
const createListenerNotification=():Observable<any>=>
  new Observable(observer=>{
    listenerObserverNotification=observer;
  })


export const sendActivity = (page: string) => {
  connection?.then(() => {
    stompClient?.send(
      '/topic/activity', // destination
      JSON.stringify({ page }), // body
      {} // header
    );
  });
};

const subscribe = () => {
  connection.then(() => {
    subscriber = stompClient.subscribe('/topic/tracker', data => {
      listenerObserver.next(JSON.parse(data.body));
    });
  });
};

const subscribeChangeAmount = () => {
  connection.then(() => {
    subscriber = stompClient.subscribe('/topic/report', data => {
      listenerObserverReport.next(JSON.parse(data.body));
    });
  });
};
const subscribeChangeDepositTransaction = () => {
  connection.then(() => {
    subscriber = stompClient.subscribe('/topic/fluctuation-tracker', data => {
      listenerObserverDepositTransaction.next(JSON.parse(data.body));
    });
  });
};
const subscribeChangeWithdrawnTransaction = () => {
  connection.then(() => {
    subscriber = stompClient.subscribe('/topic/withdraw-tracker', data => {
      listenerObserverWithdrawnTransaction.next(JSON.parse(data.body));
    });
  });
};
const subscribeNotification= () => {
  connection.then(() => {
    subscriber = stompClient.subscribe('/topic/notification-tracker', data => {
      listenerObserverNotification.next(JSON.parse(data.body));

    });
  });
};


const connect = () => {
  if (connectedPromise !== null || alreadyConnectedOnce) {
    return;
  }
  connection = createConnection();
  listener = createListener();
  listenerReport = createListenerReport();
  listenerDepositTransaction=createListenerDepositTransaction();
  listenerWithdrawnTransaction=createListenerWithdrawnTransaction()
  listenerNotification=createListenerNotification()
  const loc = window.location;
  const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');
  const headers = {};
  let url = '//' + loc.host + baseHref + '/websocket/tracker';
  const authToken = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
  if (authToken) {
    url += '?access_token=' + authToken;
  }
  const socket = new SockJS(url);
  stompClient = Stomp.over(socket, { protocols: ['v12.stomp'] });
  stompClient.connect(headers, () => {
    connectedPromise('success');
    connectedPromise = null;
    sendActivity(window.location.pathname);
    alreadyConnectedOnce = true;
  });
};

const disconnect = () => {
  if (stompClient !== null) {
    if (stompClient.connected) {
      stompClient.disconnect();
    }
    stompClient = null;
  }
  alreadyConnectedOnce = false;
};

const receive = () => listener;
const receiveReport = () => listenerReport;
const receiveDepositTransactionToday = () => listenerDepositTransaction;
const receiveWithdrawnTransactionToday = () => listenerWithdrawnTransaction;
const receiveNotification = () => listenerNotification;

const unsubscribe = () => {
  if (subscriber !== null) {
    subscriber.unsubscribe();
  }
  listener = createListener();
};

const playSound = () => {
  const audio = new Audio('content/sound/money.mp3');
  audio.play();
}

export default store  => next => action => {
  if (getAccount.fulfilled.match(action)) {
    connect();
    if (!alreadyConnectedOnce) {

      subscribe();
      receive().subscribe(activity => {
        // debugger;
        return store.dispatch(websocketActivityMessage(activity));
      });
      subscribeChangeAmount()
      receiveReport().subscribe(report => {
        // debugger;
        return store.dispatch(websocketReportData(report));
      });
      subscribeChangeDepositTransaction();
      receiveDepositTransactionToday().subscribe(deposit => {
        const amount = `${deposit?.amount}`;
        const accountName = `${deposit?.recipientBankAccount?.accountName}`;
        const createdDate=`${deposit?.createdDate}`
        const message=`${deposit?.message}`
        const utcDate=new Date(createdDate)
        const localDateString = utcDate.toLocaleString();
        const toastMessage = `Account name${' '}:${accountName}(VND) +${amount} at ${localDateString},message:${' '}${message} `;
        playSound();
        return(
          toast.success(toastMessage,{
            position:toast.POSITION.TOP_RIGHT,
            autoClose:3000,
          })
        )
      });
      subscribeChangeWithdrawnTransaction();
      receiveWithdrawnTransactionToday().subscribe(withdrawn => {
        const amount = withdrawn?.requestWithdraw?.amount ?? 0;
        const accountName = `${withdrawn?.requestWithdraw?.bankAccountName}`;
        const bankName = `${withdrawn?.requestWithdraw?.bankName}`;
        const bankAccountNumber = `${withdrawn?.requestWithdraw?.bankAccountNumber}`;
        const createdDate=`${withdrawn?.createdDate}`
        const utcDate=new Date(createdDate)
        const localDateString = utcDate.toLocaleString();
        const toastMessage = `-${formatCurrency(amount)}\n Successfully transferred ${formatCurrency(amount)} to (${bankName}) ${accountName}(${bankAccountNumber}) at ${localDateString}`;
        playSound();
        return(
          toast.info(toastMessage,{
            position:toast.POSITION.TOP_RIGHT,
            autoClose:3000,
          })
        )
      });
      subscribeNotification();
      receiveNotification().subscribe(notification => {

        // debugger;
        return store.dispatch(websocketNotificationToday(notification));
      });

    }
  } else if (getAccount.rejected.match(action) || action.type === logoutSession().type) {
    unsubscribe();
    disconnect();
  }
  return next(action);
};
