import React from 'react';
import { Translate } from 'react-jhipster';

import MenuItem from 'app/shared/layout/menus/menu-item';
import { useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

const EntitiesMenu = () => {
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isOperator1 = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.OPERATOR1]));
  const isOperator2 = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.OPERATOR2]));
  const isOperator3 = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.OPERATOR3]));
  return (
    <>
      {/* prettier-ignore */}
      {(isAdmin || isOperator1) && (
        <MenuItem icon="asterisk" to="/user-information">
          <Translate contentKey="global.menu.entities.userInformation" />
        </MenuItem>
      )}
      {(isAdmin || isOperator2) && (
        <MenuItem icon="asterisk" to="/system-bank">
          <Translate contentKey="global.menu.entities.systemBank" />
        </MenuItem>
      )}
      {(isAdmin || isOperator2) && (
        <MenuItem icon="asterisk" to="/system-setting">
          <Translate contentKey="global.menu.entities.systemSetting" />
        </MenuItem>
      )}
      {(isAdmin || isOperator3) && (
        <MenuItem icon="asterisk" to="/api-key">
          <Translate contentKey="global.menu.entities.apiKey" />
        </MenuItem>
      )}
      {(isAdmin || isOperator1 || isOperator2) && (
        <MenuItem icon="asterisk" to="/request-withdraw">
          <Translate contentKey="global.menu.entities.requestWithdraw" />
        </MenuItem>
      )}
      {isAdmin && (
        <MenuItem icon="asterisk" to="/token">
          <Translate contentKey="global.menu.entities.token" />
        </MenuItem>
      )}
      {(isAdmin || isOperator1 || isOperator2) && (
        <MenuItem icon="asterisk" to="/deposit-transaction">
          <Translate contentKey="global.menu.entities.depositTransaction" />
        </MenuItem>
      )}
      {(isAdmin || isOperator1 || isOperator2) && (
        <MenuItem icon="asterisk" to="/withdraw-transaction">
          <Translate contentKey="global.menu.entities.withdrawTransaction" />
        </MenuItem>
      )}
      {(isAdmin || isOperator1 || isOperator3) && (
        <MenuItem icon="asterisk" to="/deposit-error">
          <Translate contentKey="global.menu.entities.depositError" />
        </MenuItem>
      )}
      {isAdmin && (
        <MenuItem icon="asterisk" to="/ip-white-list">
          <Translate contentKey="global.menu.entities.ipWhiteList" />
        </MenuItem>
      )}
      {isAdmin && (
        <MenuItem icon="asterisk" to="/activity-log">
          <Translate contentKey="global.menu.entities.activityLog" />
        </MenuItem>
      )}
      {isAdmin && (
        <MenuItem icon="asterisk" to="/regex">
          <Translate contentKey="global.menu.entities.regex" />
        </MenuItem>
      )}
      {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
    </>
  );
};

export default EntitiesMenu;
