import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { formatCurrency } from 'app/common/validate';
import './index.scss';

interface CardProps {
  currentAmount?: number | null;
  totalWithdrawn: number | null;
  totalDeposit: number | null;
  listSystemBankDeposit: number | null;
  totalMoneyDeposit: number | null;
  listSystemBankWithDrawn: number | null;
  totalMoneyWithDrawn: number | null;
  icon: React.ReactNode;
  totalDepositErrorInDay: number | null;
}

const useStyles = makeStyles<void>()(() => ({
  container_card: {
    margin: '0px 40px',
  },
  currentAmount: {
    color: '#000000',
    fontSize: '14px',
  },

  total_bank: {
    fontSize: '16px',
    color: '#000000',
    fontWeight: 'bold',
  },
  bgLink: {
    backgroundColor: '#ccc',
    opacity: 0.7,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    maxHeight: '30px',
  },
  nameLink: {
    textAlign: 'center',
    color: '#fff',
    fontWeight: 600,
    textDecoration: 'none',
  },
  money: {
    fontWeight: 600,
    color: '#ffffff',
    fontSize: '20px',
    paddingTop: '5px',
  },
  icon: {
    fontSize: '18px',
  },
}));
const CardOutline: React.FC<CardProps> = props => {
  const {
    currentAmount,
    totalWithdrawn,
    totalDeposit,
    listSystemBankDeposit,
    totalMoneyDeposit,
    listSystemBankWithDrawn,
    totalMoneyWithDrawn,
    totalDepositErrorInDay,
  } = props;
  const { classes } = useStyles();
  return (
    <div className="card-full-wrapper">
      <Grid item xs={12} md={6} lg={2}>
        <Card style={{ minHeight: '100px', background: '#D83F31' }}>
          <CardContent>
            <div className="box-card">
              <div className="card-header">
                <Typography className={classes.currentAmount} variant="h1" component="div">
                  Total Deposit Error Today
                </Typography>
                <p className={classes.money}> {totalDepositErrorInDay}</p>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={2}>
        <Card style={{ minHeight: '100px', background: '#17A2B8' }}>
          <CardContent>
            <div className="box-card">
              <div className="card-header">
                <Typography className={classes.currentAmount} variant="h1" component="div">
                  Total Amount
                </Typography>
                <p className={classes.money}> {formatCurrency(currentAmount)}</p>
              </div>
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={3} sx={{ position: 'relative' }}>
        <Card style={{ minHeight: '100px', background: '#FFC107' }}>
          <CardContent>
            <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography className={classes.currentAmount} variant="h1" component="div">
                  The total number of withdrawal requests awaiting processing
                </Typography>
                <p className={classes.money}> {totalWithdrawn}</p>
              </div>
              <div className="content-deposit">
                <Typography className={classes.currentAmount} variant="h1" component="div" style={{ textAlign: 'right' }}>
                  Total number of failed deposit requests
                </Typography>
                <p className={classes.money} style={{ textAlign: 'center' }}>
                  {' '}
                  {totalDeposit}
                </p>
              </div>
              <br />
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6} lg={3.5} sx={{ position: 'relative' }}>
        <Card style={{ minHeight: '100px', background: '#28A745' }}>
          <CardContent>
            <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography className={classes.currentAmount} variant="h1" component="div">
                  The amount that can be deposited
                </Typography>
                <p className={classes.money}> {formatCurrency(totalMoneyDeposit)}</p>
              </div>
              <div className="content-deposit">
                <Typography className={classes.currentAmount} variant="h1" component="div">
                  The total number of banks that can be used for deposit
                </Typography>
                <p className={classes.money} style={{ textAlign: 'center' }}>
                  {' '}
                  {listSystemBankDeposit}
                </p>
              </div>
              <br />
            </div>
          </CardContent>
        </Card>
        <Box className="view-more" sx={{ position: 'absolute', bottom: '0%', width: '100%' }}>
          <div className={classes.bgLink}>
            <Link to="/deposit-amount" className={classes.nameLink}>
              <span style={{ color: '#000' }}>View more</span> <BsFillArrowRightCircleFill className={classes.icon} />
            </Link>
          </div>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} lg={3.5} sx={{ position: 'relative' }}>
        <Card style={{ minHeight: '100px', background: '#fc7f03' }}>
          <CardContent>
            <div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography className={classes.currentAmount} variant="h1" component="div">
                  The amount of money that can be withdrawn
                </Typography>
                <p className={classes.money}> {formatCurrency(totalMoneyWithDrawn)}</p>
              </div>
              <div className="content-deposit">
                <Typography className={classes.currentAmount} variant="h1" component="div">
                  Total Bank Withdrawn
                </Typography>
                <p className={classes.money} style={{ textAlign: 'center' }}>
                  {' '}
                  {listSystemBankWithDrawn}
                </p>
              </div>
              <br />
            </div>
          </CardContent>
        </Card>
        <Box className="view-more" sx={{ position: 'absolute', bottom: '0%', width: '100%' }}>
          <div className={classes.bgLink}>
            <Link to="/with-drawn-amount" className={classes.nameLink}>
              <span style={{ color: '#000' }}>View more</span> <BsFillArrowRightCircleFill className={classes.icon} />
            </Link>
          </div>
        </Box>
      </Grid>
    </div>
  );
};

export default CardOutline;
