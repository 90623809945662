import dayjs from 'dayjs';
import { TokenType } from 'app/shared/model/enumerations/token-type.model';

export interface IToken {
  id?: string;
  token?: string | null;
  expiryDate?: string | null;
  type?: TokenType | null;
  createdDate?: string | null;
  updatedDate?: string | null;
}

export const defaultValue: Readonly<IToken> = {};
