import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IIpWhiteList } from 'app/shared/model/ip-white-list.model';
import { getEntity, updateEntity, createEntity, reset } from './ip-white-list.reducer';

export const IpWhiteListUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const ipWhiteListEntity = useAppSelector(state => state.ipWhiteList.entity);
  const loading = useAppSelector(state => state.ipWhiteList.loading);
  const updating = useAppSelector(state => state.ipWhiteList.updating);
  const updateSuccess = useAppSelector(state => state.ipWhiteList.updateSuccess);

  const handleClose = () => {
    navigate('/ip-white-list' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.updatedDate = convertDateTimeToServer(values.updatedDate);

    const entity = {
      ...ipWhiteListEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          updatedDate: displayDefaultDateTime(),
        }
      : {
          ...ipWhiteListEntity,
          createdDate: convertDateTimeFromServer(ipWhiteListEntity.createdDate),
          updatedDate: convertDateTimeFromServer(ipWhiteListEntity.updatedDate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="cesPaymentGatewayApp.ipWhiteList.home.createOrEditLabel" data-cy="IpWhiteListCreateUpdateHeading">
            <Translate contentKey="cesPaymentGatewayApp.ipWhiteList.home.createOrEditLabel">Create or edit a IpWhiteList</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="ip-white-list-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('cesPaymentGatewayApp.ipWhiteList.ip')}
                id="ip-white-list-ip"
                name="ip"
                data-cy="ip"
                type="text"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.ipWhiteList.isActive')}
                id="ip-white-list-isActive"
                name="isActive"
                data-cy="isActive"
                check
                type="checkbox"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.ipWhiteList.createdDate')}
                id="ip-white-list-createdDate"
                name="createdDate"
                data-cy="createdDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <ValidatedField
                label={translate('cesPaymentGatewayApp.ipWhiteList.updatedDate')}
                id="ip-white-list-updatedDate"
                name="updatedDate"
                data-cy="updatedDate"
                type="datetime-local"
                placeholder="YYYY-MM-DD HH:mm"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/ip-white-list" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default IpWhiteListUpdate;
