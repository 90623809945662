import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getSortState, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { BankStatus } from 'app/shared/model/enumerations/bank-status.model';
import { approveWithdraw, createEntity, getEntity, reset, updateEntity } from './request-management.reducer';
import TagRequest from './request-select/request-tag';
import { getEntities } from '../system-bank/system-bank.reducer';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { toast } from 'react-toastify';
import { BankInfo, IRequestWithdraw } from 'app/shared/model/request-withdraw.model';
import { getEntities as getAllSystemSetting } from 'app/entities/system-setting/system-setting.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export const RequestManagementUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const requestDrawnManagementEntity = useAppSelector(state => state.requestManagementRequest.entity);

  const systemBankList = useAppSelector(state => state.systemBank.entities);
  const loading = useAppSelector(state => state.requestManagementRequest.loading);
  const updating = useAppSelector(state => state.requestManagementRequest.updating);
  const updateSuccess = useAppSelector(state => state.requestManagementRequest.updateSuccess);
  const bankStatusValues = Object.keys(BankStatus);
  const [selectedAccounts, setSelectedAccounts] = useState<BankInfo[]>([]);
  const systemSettingList = useAppSelector(state => state.systemSetting.entities);
  const maxWithdraw = (systemSettingList && systemSettingList[0]?.maxWithdraw) ?? 100000000;
  const maxWithdrawInDay = (systemSettingList && systemSettingList[0]?.maxWithdrawInDay) ?? 100000000;
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id', 'desc'), location.search)
  );
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: 0,
        size: 100000,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleClose = () => {
    navigate('/request-draw-management' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getAllSystemSetting({}));
    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const totalBalance = selectedAccounts.reduce((accumulator, currentItem) => {
      const balanceToAdd = (currentItem.balance < maxWithdrawInDay ? currentItem.balance : maxWithdrawInDay) - currentItem.amountWithdrawn;
      return accumulator + balanceToAdd;
    }, 0);
    if (totalBalance < values.amount) return toast.warning('The balance is insufficient, please select more bank');

    values.createdDate = convertDateTimeToServer(values.createdDate);
    values.updatedDate = convertDateTimeToServer(values.updatedDate);

    const { isSkipCallback, ...requestWithdraw } = values;

    const entity = {
      requestWithdraw: requestWithdraw as IRequestWithdraw,
      listSystemBank: selectedAccounts,
      skipCallback: isSkipCallback ?? false,
    };

    dispatch(approveWithdraw(entity));
  };

  const defaultValues = () =>
    isNew
      ? {
          createdDate: displayDefaultDateTime(),
          updatedDate: displayDefaultDateTime(),
        }
      : {
          status: 'COMPLETED',
          ...requestDrawnManagementEntity,
          createdDate: convertDateTimeFromServer(requestDrawnManagementEntity.createdDate),
          updatedDate: convertDateTimeFromServer(requestDrawnManagementEntity.updatedDate),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="cesPaymentGatewayApp.requestWithdraw.home.createOrEditLabel" data-cy="RequestWithdrawCreateUpdateHeading">
            Approve the withdrawal request
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  disabled
                  name="id"
                  required
                  readOnly
                  id="request-withdraw-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                disabled
                label={translate('cesPaymentGatewayApp.requestWithdraw.amount')}
                id="request-withdraw-amount"
                name="amount"
                data-cy="amount"
                type="text"
              />
              <ValidatedField
                disabled
                label={translate('cesPaymentGatewayApp.requestWithdraw.status')}
                id="request-withdraw-status"
                name="status"
                data-cy="status"
                type="select"
              >
                {bankStatusValues.map(bankStatus => (
                  <option value={bankStatus} key={bankStatus}>
                    {translate('cesPaymentGatewayApp.BankStatus.' + bankStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                disabled
                label={translate('cesPaymentGatewayApp.requestWithdraw.confirmedId')}
                id="request-withdraw-confirmedId"
                name="confirmedId"
                data-cy="confirmedId"
                type="text"
              />
              <ValidatedField
                disabled
                label={translate('cesPaymentGatewayApp.requestWithdraw.bankName')}
                id="request-withdraw-bankName"
                name="bankName"
                data-cy="bankName"
                type="text"
              />
              <ValidatedField
                disabled
                label={translate('cesPaymentGatewayApp.requestWithdraw.branch')}
                id="request-withdraw-branch"
                name="branch"
                data-cy="branch"
                type="text"
              />
              <ValidatedField
                disabled
                label={translate('cesPaymentGatewayApp.requestWithdraw.swiftCode')}
                id="request-withdraw-swiftCode"
                name="swiftCode"
                data-cy="swiftCode"
                type="text"
              />
              <ValidatedField
                disabled
                label={translate('cesPaymentGatewayApp.requestWithdraw.bankAccountName')}
                id="request-withdraw-bankAccountName"
                name="bankAccountName"
                data-cy="bankAccountName"
                type="text"
              />
              <ValidatedField
                disabled
                label={translate('cesPaymentGatewayApp.requestWithdraw.bankAccountNumber')}
                id="request-withdraw-bankAccountNumber"
                name="bankAccountNumber"
                data-cy="bankAccountNumber"
                type="text"
              />
              <TagRequest
                listBank={systemBankList}
                onCallbackBanks={banks => {
                  setSelectedAccounts(banks);
                }}
                amount={defaultValues().amount}
                maxWithdraw={maxWithdraw}
                maxWithdrawInDay={maxWithdrawInDay}
              />
              {isAdmin && (
                <ValidatedField
                  label={'Skip Callback'}
                  id="request-withdraw-isSkipCallback"
                  name="isSkipCallback"
                  data-cy="isSkipCallback"
                  check
                  type="checkbox"
                />
              )}
              <div style={{ marginTop: 20 }}></div>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/request-draw-management" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Approve
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RequestManagementUpdate;
